import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography,IconButton} from '@mui/material';
import ReportGen from '../reports/ReportGen';
import AnnualReportCard from './AnnualReportCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import {  viewedReportDataState } from '../../recoil/atoms';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import configParam from '../../config';
import CircularProgress from '@mui/material/CircularProgress';
import { userPlantState,userLocationState} from '../../recoil/atoms';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from "@mui/icons-material/Add";
import { plantId } from '../../recoil/atoms';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const access_token=localStorage.getItem("accesstoken");

export default function Reports() {
 
 
  const [initialOption, setInitialOption] = useState('annualrep');
  const [submittedReports, setSubmittedReports] = useState([]);
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
  const [selectedYear, setSelectedYear] = useState('');
  const [userDet, setUserDet] = useState({});
  const [iconsVisible, setIconsVisible] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [reportDataByYear, setReportDataByYear] = useState({});
  const [PlantId, setPlantId] = useRecoilState(plantId);
  const [, setPlantDet] = useState('');
  const [breadcrumb, setBreadcrumb] = useState('');
  const [loading, setLoading] = useState(false);
  const userPlant = useRecoilValue(userPlantState);
  const userLocation=useRecoilValue(userLocationState);
  const [, setLocationDet] = useState({});
  const datauser=localStorage.getItem("user_id")
  let data = localStorage.getItem('user');
  let User = JSON.parse(data);
  const userlogoutdata = datauser!=null?datauser:User.plant_id[0];
 
  const [openReportGenModal, setOpenReportGenModal] = useState(false);
  const [reportGenInitialValues, setReportGenInitialValues] = useState(null);
  const [expandedYears, setExpandedYears] = useState({customReports: true, });

  const ApiURL = configParam.API_URL;

  const location = useLocation();
 
  




  useEffect(() => {
    // Extract the last part of the current path as the breadcrumb
    const pathnames = location.pathname.split('/').filter((path) => path);
    if (pathnames.length > 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 2];
      setBreadcrumb(currentBreadcrumb);
    }
    if (pathnames.length === 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 1];
      setBreadcrumb(currentBreadcrumb);
    }
  }, [location]);
  useEffect(() => {
    // Set initial expanded state for year cards if available
    if (reportDataByYear.annualReports) {
      const initialYearExpandedState = {};
      Object.keys(reportDataByYear.annualReports).forEach((year) => {
        initialYearExpandedState[year] = true; // Set to true for initial expanded state
      });
  
      setExpandedYears((prevExpandedYears) => ({
        ...prevExpandedYears,
        ...initialYearExpandedState,
      }));
    }
  }, [reportDataByYear.annualReports]);
  useEffect(() => {
    setLocationDet(userLocation || {});
  }, [userLocation]);
  useEffect(() => {
setPlantDet(userPlant)
  }, [userPlant]);

  function formatBusinessName(name) {
    if (name) {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }
    return ''; // Handle the case where name is undefined or null
  }
  useEffect(() => {
    if (location.pathname === '/reports') {
      getReportDetails();
    }
  }, [location.pathname]);

  useEffect(() => {
    let data = localStorage.getItem("user");
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);

  useEffect(() => {
    if (userDet) {
      setIconsVisible(userDet.role_id === 3 || userDet.role_id === 1);
    }
  }, [userDet]);

  const getReportDetails = (plantName) => {
    const PlantId = plantName;
    let url = ApiURL + `/get_reports/${userlogoutdata}` ;
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
)
      .then((response) => {
        
        setSubmittedReports(response.data);
        organizeReportsByYear(response.data);
      })
      .catch((error) => {
        setSubmittedReports([]);
      });
  };
 
  const handleToggleExpand = (year) => {
    setExpandedYears((prevExpandedYears) => ({
      ...prevExpandedYears,
      [year]: !prevExpandedYears[year],
    }));
  };
  const organizeReportsByYear = (reports) => {
    const organizedData = {
      annualReports: {},
      customReports: [],
    };
  
    reports.forEach((report) => {
      const year = report.year || ''; // You can modify this logic if necessary
  
      if (report.fromdate && report.todate) {
        
        // If the report has both fromDate and toDate, consider it as a Custom Report
        organizedData.customReports.push(report);
       }else {

       
        // Otherwise, consider it as an Annual Report
        if (!organizedData.annualReports[year]) {
          organizedData.annualReports[year] = [];
        }
        organizedData.annualReports[year].push(report);
      }
    
    });
  
    setReportDataByYear(organizedData);
  };
  
 
 
  
  const handleGenerateReport = () => {
    setOpenReportGenModal(true);
    setInitialOption('annualrep');
    setSelectedYear('');
    navigate({ state: { selectedYear: selectedYear } });
  
  };
 

  const handleGenerateReportData = (reportData) => {
    if (reportData) {
      const { id, year, fromdate, todate} = reportData;
      const existingReportIndex = submittedReports.findIndex(
        (existingReport) => existingReport.id === id
      );
      if (existingReportIndex !== -1) {
        const updatedReports = [...submittedReports];
        updatedReports[existingReportIndex] = reportData;
        setSubmittedReports(updatedReports);
        organizeReportsByYear(updatedReports);
      } else {
        setSubmittedReports([...submittedReports, reportData]);
        organizeReportsByYear([...submittedReports, reportData]);
      }
      if (year) {
        // If year is present, navigate to Annual Report
        navigate(`/AnnualReport`, { state: { selectedYear: year} });
      } else if (fromdate && todate) {
        navigate(`/customreport`, { state: { selectedFromDate: fromdate, selectedToDate: todate } });
      }
      setOpenReportGenModal(true);
     
    }
  };
  



  
  

  
  const handleCardClick = (reportData) => {
   
    if (reportData.year) {
      axios.get(`${ApiURL}/get_reportsbyId/${reportData.id}`,{
        headers: {
          Authorization: `Bearer ${access_token}`,
        
        }
      }
)
        .then((response) => {
          
          const updatedReportData = {
            ...response.data[0],
            reportType: reportData.reportType,
            year: reportData.year || '',
            created_by_filter: response.data[0].created_by_filter || []
          };
          setViewedReportData(updatedReportData);
          setOpenReportGenModal(true);
          setReportGenInitialValues({
            year: updatedReportData.year,
            
            reportName: updatedReportData.report_name,
            created_by_filter: updatedReportData.created_by_filter || [],
            plant_id: updatedReportData.plant_id,
          });
          setInitialOption('annualrep');
         
        })
        .catch((error) => {
          console.error('Error fetching report data:', error);
        });
    } else {
      axios.get(`${ApiURL}/get_reportsbyId/${reportData.id}`,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        }
      }
)
        .then((response) => {
          const updatedReportData = {
            ...response.data[0],
            reportType: reportData.reportType,
            fromdate: reportData.fromdate || '',
            todate: reportData.todate || '',
            created_by_filter: response.data[0].created_by_filter || []
          };
          setViewedReportData(updatedReportData);
          setOpenReportGenModal(true);
          setReportGenInitialValues({
            fromdate: updatedReportData.fromdate,
            todate: updatedReportData.todate,
            reportName: updatedReportData.report_name,
            created_by_filter: updatedReportData.created_by_filter || [],
            plant_id: updatedReportData.plant_id,
          });
          setInitialOption('custrep');
        })
        .catch((error) => {
          console.error('Error fetching report data:', error);
        });
    }
  };
  
  
  

  const handleDeleteReport = (reportId) => {
    axios.delete(`${ApiURL}/delete_report/${reportId}`,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
)
      .then((response) => {
        const submittedreportfilter = submittedReports.filter((report) => report.id !== reportId);
        setSubmittedReports(submittedreportfilter);
        organizeReportsByYear(submittedreportfilter);
      })
      .catch((error) => {
        console.error('Failed to delete report:', error);
      });
  };
  const isMobileView = useMediaQuery('(max-width:600px)');
 


  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                     {loading && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',position:'fixed',top:0,width:'100%',background:'#000000ab',zIndex:'9999' }}>
        <CircularProgress /> {/* Show circular progress loader while loading is true */}
      </div>
    )}
      <Grid container  justifyContent={'space-between'} alignItems={'center'} style={{ display: 'flex' }}>
        <Grid item >
           <Typography style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 600 }}>{formatBusinessName(breadcrumb)}</Typography>
           
        </Grid>
        {iconsVisible && (
          <Grid item marginTop={1}>
            {isMobileView ? (
               <Button
               variant="contained"
               startIcon={<AddIcon />}
               style={{ backgroundColor: '#002060',display: 'flex',
              
               justifyContent: 'center',
               alignItems: 'center', }}
               onClick={handleGenerateReport}
             >

             </Button>
            ) : (
              <Button
                variant="contained"
                style={{ backgroundColor: '#002060',display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',  marginLeft: '990px',}}
                onClick={handleGenerateReport}
              >
                Generate Report
              </Button>
              
            )}
          </Grid>
        )}



<>

     </>
      </Grid>
      <Grid container style={{ padding: '5px 2px 10px  ' }} alignItems={'center'} justifyContent={'space-between'}>
                  <Grid item >
                  {isMobileView ? (
                    <Typography style={{fontSize:"12px",fontWeight:400,color:"#8C8C8C"}}>
                    Get insights quickly with your Reports
                    </Typography>
                  ) : (
           
                  null
                  )}
                  </Grid>
      </Grid>
      <hr style={{ margin: '1px 0', borderColor: '#0000001A' }} />
      <ReportGen
  open={openReportGenModal}
  onClose={() => setOpenReportGenModal(false)}
  initialOption={initialOption}

  onGenerateReport={handleGenerateReportData}
  reportData={reportGenInitialValues}
  viewedReportData={viewedReportData} 
/>
    
     
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
      {reportDataByYear.annualReports && Object.keys(reportDataByYear.annualReports).length > 0 && (
  <>
    {Object.keys(reportDataByYear.annualReports).map((year) => (
      <div key={year} style={{ margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => handleToggleExpand(year)}>
                  <ExpandMoreIcon />
                </IconButton>
          <Typography variant="h6" gutterBottom style={{ marginLeft: '10px' }}>{year}</Typography>
        </div>
        {expandedYears[year] && (
          <Grid container spacing={2}>
            {reportDataByYear.annualReports[year]?.map((reportData, index) => (
              <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
             

                <AnnualReportCard
                  key={index}
                  reportData={reportData}
                  setLoadingpdf={setLoading}
                  onEditClick={handleCardClick}
                  activeTab={activeTab}
                  onViewTabChange={setActiveTab}
                  onDeleteClick={handleDeleteReport}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    ))}
  </>
)}




{reportDataByYear.customReports && reportDataByYear.customReports.length > 0 && (
      <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => handleToggleExpand('customReports')}>
            <ExpandMoreIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom style={{ marginLeft: '10px' }}>Custom Reports</Typography>
        </div>
        {expandedYears['customReports'] && (
          <Grid container spacing={2}>
            {reportDataByYear.customReports.map((reportData, index) => (
              <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                
                <AnnualReportCard
                  key={index}
                  reportData={reportData}
                  setLoadingpdf={setLoading}
                  onEditClick={handleCardClick}
                  activeTab={activeTab}
                  onViewTabChange={setActiveTab}
                  onDeleteClick={handleDeleteReport}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    )}

       
      </div>
    </div>
  );
        }