import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { menuList } from '../../recoil/atoms';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Tooltip,

  IconButton,
} from '@mui/material';
import Routes from '../../routes';
import { useMediaQuery, createTheme } from '@mui/material';
import { ReactComponent as AppsIcon } from '../../assets/img/apps.svg';
import { plantId, UserSettings } from '../../recoil/atoms';


import { useNavigate, } from "react-router-dom";
const drawerWidth = 220;
function NavDrawer({ toggleDrawerOpen, open }) {
  const theme = createTheme(); // Create or import your theme object
  const matches = useMediaQuery(theme.breakpoints.up('sm'));


  const [, setMenu] = useRecoilState(menuList);
  const [userDet, setUserDet] = useState({});

  const [, setSelectedItem] = useState(null);
  const menu = useRecoilValue(menuList);
  const [PlantId, _] = useRecoilState(plantId);
  const navigate = useNavigate();
  const [userDataSettings, setUserDataSettings] = useRecoilState(UserSettings)
  // useEffect(()=>{
  //   let data = localStorage.getItem("user");
  //   let User = JSON.parse(data)
  //   setUserDet(User);
  // },[]);
  useEffect(() => {
    let data = localStorage.getItem("user");
    let User = JSON.parse(data)

    setUserDet(User);
  }, [userDataSettings]);
  useEffect(() => {
    if (matches) {
      // Code to execute when matches is true
      toggleDrawerOpen(true);  // for example
    } else {
      // Code to execute when matches is false
      toggleDrawerOpen(false);
    }
  }, [matches]);

  const changeMenu = (name) => {
    setMenu(name);
    setSelectedItem(name);
  };
  const handleDashboardClick = () => {

    navigate(`/Analytics/${PlantId}`);
  };

  function getListItemSet(text, index) {
    const isSelected = menu === text.name;
    const textColor = isSelected ? '#000' : '#757575';
    const backgroundColor = isSelected ? '#f2f2f2' : '';
    const iconColor = isSelected ? '#000' : '#757575';

    return text.icon ? (
      <NavLink key={index} style={{ textDecoration: 'none' }} to={text.path}>
        <ListItem
          disablePadding
          sx={{
            display: 'block',
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 1,
            paddingBottom: 1,
            marginBottom: 1,
            backgroundColor: backgroundColor
          }}
          onClick={() => changeMenu(text.name)}
        >
          <ListItemButton
            sx={{
              minHeight: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5
            }}
          >
            <SvgIcon style={{ color: iconColor, marginRight: 12 }}>
              <Tooltip title={text.name} placement="right">
                <text.icon />
              </Tooltip>
            </SvgIcon>
            {open && (
              <ListItemText
                className="Drawer-ListItemText"
                primary={text.name === 'Logout' ? text.name + ' - ' + userDet.name : text.name}
                sx={{ opacity: 1 }}
                style={{ color: textColor }}
              />
            )}
            <ListItemText
              className="Dashboardpage"
              primary={handleDashboardClick}
            />
          </ListItemButton>
        </ListItem>
      </NavLink>
    ) : null;
  }

  const drawer = (
    <div>
      <List>
        {Routes.mainRoutes.map((text, index) =>
          (text.name !== "Settings" || userDet.role_id === 1 || userDet.role_id === 3) ? (
            getListItemSet(text, index)
          ) : null
        )}
      </List>
    </div>
  );

  return (
    <div>
      <div className='desktop-view'>
        <Drawer
          variant={matches ? 'permanent' : 'persistent'}
          style={{
            whiteSpace: 'nowrap',
            width: open ? drawerWidth : 110,

            position: 'relative',
          }}

          open={open}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  minHeight: 58,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}
                onClick={toggleDrawerOpen}
              >
                <IconButton
                  color="inherit"
                  aria-label="toggle drawer"
                  edge="start"
                  sx={{ display: 'block', cursor: 'pointer', width: 48, height: 48 }}
                >
                  <AppsIcon />
                </IconButton>
                {open && <ListItemText primary="NiFi Application" sx={{ opacity: 1 }} style={{ color: 'black' }} />}
              </ListItemButton>
            </ListItem>
            {drawer}
            {/* SIDEBAR LIST OF ICONS BOTTOM*/}
            <List>
              {Routes.mainRoutes.map((text, index) =>
                text.footer && text.iconFlag ? getListItemSet(text, index) : null
              )}
            </List>
          </List>
        </Drawer>
      </div>
      <div className='mobile-view'>
        <Drawer
          variant="persistent"
          style={{
            whiteSpace: 'nowrap',
            position: 'fixed',
            width: open ? '220px' : '0',
            left: '0',
            top: '0',
            height: '100%',
            zIndex: 9999,
          }}
          open={open}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  minHeight: 58,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5
                }}
                onClick={toggleDrawerOpen}
              >
                <IconButton
                  color="inherit"
                  aria-label="toggle drawer"
                  edge="start"
                  sx={{ display: 'block', cursor: 'pointer', width: 48, height: 48 }}
                >
                  <AppsIcon />
                </IconButton>
                {open && <ListItemText primary="NiFi Application" sx={{ opacity: 1 }} style={{ color: 'black' }} />}
              </ListItemButton>
            </ListItem>
            {drawer}
            {/* SIDEBAR LIST OF ICONS BOTTOM*/}
            <List>
              {Routes.mainRoutes.map((text, index) =>
                text.footer && text.iconFlag ? getListItemSet(text, index) : null
              )}
            </List>
          </List>
        </Drawer>
      </div>
    </div>


  );
}
export default NavDrawer;