import React, { useState, useEffect } from 'react';
import { Button,  TextField, InputLabel } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import configParam from '../../config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";

const access_token=localStorage.getItem("accesstoken");

const filter = createFilterOptions();
function AddPlant({ open, onClose, onSave, selectedRowPlantData,selectedPlantCardData,addedAreas,onHandleArea,onShowAlertMessage,userDet }) {
  const [business, setBusiness] = useState(null);
  const [category, setCategory] = useState(null);
  const [tabledata, setTableData] = useState([]);
  const [plantdata, setPlantData] = useState([]);
  const [businessdata, setBusinessData] = useState([]);
  const [categorydata, setCategoryData] = useState([]);
  const [location, setLocation] = useState(null);
  const [locationdata, setLocationData] = useState([]);
  const [plant, setPlant] = useState(null);
  const [inputPlant, setInputPlant] = useState(null);
  const [inputValuePlant, setInputValuePlant] = useState('');
  const [isInputValuePlantInitialized, setInputValuePlantInitialized] = useState(false);
  const [isCursorInsideDialog, setIsCursorInsideDialog] = useState(false);
  const ApiURL = configParam.API_URL;

  const modalInputLabelStyle = {
 
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPlantAsyncDetails();
        setPlantData(response.data);
      } catch (error) {
        setPlantData([]);
      }
    };
  
    fetchData();
  }, [selectedRowPlantData,selectedPlantCardData]);
  
  useEffect(() => {
    if (selectedRowPlantData && plantdata.length > 0) {
      // If selectedRowData is present, it's an edit operation
      const selectedBusiness = businessdata.find((option) => option.business_name.toLowerCase() === selectedRowPlantData.business.toLowerCase());
      const selectedLocation = locationdata.find((option) =>option.location.toLowerCase() === selectedRowPlantData.location.toLowerCase());
      
      const selectedPlant = plantdata.find((option) => option.plantName.toLowerCase() === selectedRowPlantData.plant.toLowerCase());
      const selectedCategory = categorydata.find((option) => option.name === selectedRowPlantData.category);
    
      setBusiness(selectedBusiness ? selectedBusiness.id : null);
      setLocation(selectedLocation ? selectedLocation.id : null)
      setPlant(selectedPlant ? selectedPlant:null);
      setCategory(selectedCategory?selectedCategory.id:null);
  
    }
    else if (selectedPlantCardData && plantdata.length > 0) {
     
      // If selectedRowData is present, it's an edit operation
      const selectedBusiness = businessdata.find((option) => option.business_name.toLowerCase() === selectedPlantCardData.business_name.toLowerCase());
      const selectedLocation = locationdata.find((option) => option.location.toLowerCase() === selectedPlantCardData.location.toLowerCase());
      const selectedPlant = plantdata.find((option) => option.plantName.toLowerCase() === selectedPlantCardData.plantName.toLowerCase());
      const selectedCategory = categorydata.find((option) => option.name=== selectedPlantCardData.category_name);
     
      setBusiness(selectedBusiness ? selectedBusiness.id : null);
      setLocation(selectedLocation ? selectedLocation.id : null)
      setPlant(selectedPlant ? selectedPlant:null);
      setCategory(selectedCategory?selectedCategory.id:null);
    }
    else {
      // If selectedRowData is null, it's an add operation
      setBusiness(null);
      setLocation(null);
      setPlant(null);
      setInputValuePlant(null);
      setCategory(null);
     
    }
 

  }, [selectedRowPlantData,selectedPlantCardData,plantdata]);
 
  useEffect(() => {
    getPlantDetails();
    getBusinessDetails();
    getLocationDetails();
    getCategoryDetails();
  }, []);

  const getPlantDetails = () => {
    let url = ApiURL + '/show_plant';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
  ).then((response) => {
      setTableData(response.data);
    }).catch((error) => {
      setTableData([]);
    });
  };
  const getPlantAsyncDetails = async () => {
    let url = ApiURL + '/show_plant';
  return await axios.get(url,{
    headers: {
      Authorization: `Bearer ${access_token}`,
     
    }
  }
);
  };
  const getBusinessDetails = () => {
    let url = ApiURL + '/show_business';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
  ).then((response) => {
      setBusinessData(response.data);
    }).catch((error) => {
      setBusinessData([]);
    });
  };
  const getCategoryDetails = () => {
    let url = ApiURL+'/show_category';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
  ).then((response) => {
    setCategoryData(response.data);
    }).catch((error) => {
      setCategoryData([]);
    });
  };
  const getLocationDetails = () => {
    let url = ApiURL + '/show_location';
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${access_token}`,
       
      }
    }
  ).then((response) => {
      setLocationData(response.data);
    }).catch((error) => {
      setLocationData([]);
    });
  };

  const handleClose = () => {
    setBusiness(null);
    setLocation(null);
    setPlant(null);
    setInputValuePlant(null);
    setInputPlant('');
    setCategory(null);
    onClose();
  };

  const handleBusinessChange = (event, newValue) => {
    setBusiness(newValue ? newValue.id : null);
  };
  const handleCategoryChange = (event, newValue) => {
    setCategory(newValue ? newValue.id : null);
  };
  const handleLocationChange = (event, newValue) => {
    setLocation(newValue ? newValue.id : null);
  };
 
  
  const handleSave = () => {
    const element =  (selectedRowPlantData || selectedPlantCardData) ? addedAreas[0] : null;
    const areaId = element ? element.id : null;
    
      const newArea = areaId
      ? {
          plant: areaId,
          business: business ? business : '',
          location: location?location:'',
          plantName:plant.inputValue?plant.inputValue:plant.plantName,
          userdet: userDet.id,
          category:category?category:'',
        }
        : plant.id
        ? {
            business: business ? business : '',
            location: location?location:'',
            plant:plant.id,
            plantName:plant.inputValue?plant.inputValue:plant.plantName,
            userdet: userDet.id,
            category:category?category:'',
        }:{
            business: business ? business : '',
            location: location?location:'',
            inputPlant:plant.inputValue,
            userdet: userDet.id,
            category:category?category:'',
          }
    if(areaId){
   
        axios
        .post(ApiURL + '/update_plant', newArea,{
          headers: {
            Authorization: `Bearer ${access_token}`,
           
          }
        }
      ) // Replace '/create_area' with your backend API endpoint to save the area data
        .then((response) => {
          onSave(newArea);
          onHandleArea();
          onShowAlertMessage('Plant updated successfully');
         
        })
        .catch((error) => {
          // Handle error
          console.error('Failed to save area:', error);
        });
      
    
    }
    else if(plant.id){
      
        axios
        .post(ApiURL + '/update_plant', newArea,{
          headers: {
            Authorization: `Bearer ${access_token}`,
           
          }
        }
      ) // Replace '/create_area' with your backend API endpoint to save the area data
        .then((response) => {
          onSave(newArea);
          onHandleArea();
          onShowAlertMessage('Plant updated successfully');
         
        })
        .catch((error) => {
          // Handle error
          console.error('Failed to save area:', error);
        });
    }
    else{
        axios
        .post(ApiURL + '/create_plant', newArea,{
          headers: {
            Authorization: `Bearer ${access_token}`,
            
          }
        }
      ) // Replace '/create_area' with your backend API endpoint to save the area data
        .then((response) => {
          // Handle successful response
          onSave(newArea);
          onHandleArea();
          onShowAlertMessage('Plant created successfully');
         
        })
        .catch((error) => {
          // Handle error
          console.error('Failed to save area:', error);
        });
     
    }
    setBusiness(null);
    setLocation(null);
    setPlant(null);
    setInputValuePlant(null);
    setInputPlant('');
    setCategory(null);
  }
    
  const handleInputChange = (event, newInputValue) => {
    setInputValuePlant({ id: inputValuePlant?.id || null, plantName: newInputValue });
    setInputPlant(newInputValue);
  };

 
  
  const renderPlantField = () => {
    if(selectedRowPlantData || selectedPlantCardData){
        return(
            <div>
              <Autocomplete
        className="autocomplete-nis"
        size="small"
        value={plant}
       
        onChange={(event, newValue) => {
          if (newValue && newValue.inputValue) {
            // Suggest creating a new option
            setPlant(newValue);
          } 
          else {
            setPlant(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
      
          // Suggest creating a new option if the user input doesn't match any options
          if (params.inputValue !== '' && !filtered.length) {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
      
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="my-autocomplete"
        options={plantdata}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.plantName.toUpperCase();
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.inputValue ? `Add "${option.inputValue}"` : option.plantName.toUpperCase()}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select or Add Option"
            variant="outlined"
            fullWidth
          />
        )}
        style={{ marginBottom: 16 }}
      />
            </div>)
    }
    else{
      return(
      <div>
        <Autocomplete
  className="autocomplete-nis"
  size="small"
  value={plant}
  onChange={(event, newValue) => {
    // setPlant(newValue);
    if (newValue && newValue.inputValue) {
      // Suggest creating a new option
      setPlant(newValue);
    }
     else {
      setPlant(newValue);
    }
  }}
  filterOptions={(options, params) => {
    const filtered = filter(options, params);

    // Suggest creating a new option if the user input doesn't match any options
    if (params.inputValue !== '' && !filtered.length) {
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  }}
  selectOnFocus
  clearOnBlur
  handleHomeEndKeys
  id="my-autocomplete"
  options={tabledata}
  getOptionLabel={(option) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.plantName.toUpperCase();
  }}
  renderOption={(props, option) => (
    <li {...props}>
      {option.inputValue ? `Add "${option.inputValue}"` : option.plantName.toUpperCase()}
    </li>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select or Add Option"
      variant="outlined"
      fullWidth
    />
  )}
  style={{ marginBottom: 16 }}
/>
      </div>)
    }
  }


  return (
    <div >
    <Dialog open={open} onClose={handleClose} fullWidth   maxWidth="xs">
     
     
        <DialogTitle style={{  paddingBottom: 0 }}>Plant Details</DialogTitle>
        <DialogContent  className={`dialog-content ${isCursorInsideDialog ? 'show-scroll' : ''}`}

     

onMouseEnter={() => {

  setIsCursorInsideDialog(true);

}}

onMouseLeave={() => {

  setIsCursorInsideDialog(false);

}}>
        <InputLabel style={modalInputLabelStyle}>Business</InputLabel>
        <Autocomplete
          className="autocomplete-nis"
          size="small"
          options={businessdata}
          getOptionLabel={(newValue) => newValue.business_name.toUpperCase()}
          onChange={handleBusinessChange}
          value={businessdata.find((option) => option.id === business) || null}
          renderInput={(params) => <TextField {...params} fullWidth />}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          style={{ marginBottom:16}}
        />
        
        <InputLabel style={modalInputLabelStyle}>Location</InputLabel>
        <Autocomplete
          className="autocomplete-nis"
          size="small"
          options={locationdata}
          getOptionLabel={(newValue) => newValue.location.toUpperCase()}
          onChange={handleLocationChange}
          value={locationdata.find((option) => option.id === location) || null}
          renderInput={(params) => <TextField {...params} fullWidth />}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          style={{ marginBottom:16}}
        />
       <InputLabel style={{ color: 'black', marginTop: '17px' }}>Plant</InputLabel>
      {renderPlantField()}
      <InputLabel style={modalInputLabelStyle}>Category</InputLabel>
        <Autocomplete
          className="autocomplete-nis"
          size="small"
          options={categorydata}
          getOptionLabel={(newValue) => newValue.name}
          onChange={handleCategoryChange}
          value={categorydata.find((option) => option.id === category) || null}
          renderInput={(params) => <TextField {...params} fullWidth />}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          style={{ marginBottom:16}}
        />
       
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #f5f5f5', padding: '10px 20px' }}>
          <Button variant="outlined" onClick={handleClose} style={{ color: '#203468', marginRight: '8px', borderColor: '#203468' }}>
            Cancel
          </Button>
          <Button variant="contained" style={{ backgroundColor: '#203468' }} onClick={handleSave}>
            Save
          </Button>
          </DialogActions>
       
      
    </Dialog>
    </div>
  );
}

export default AddPlant;