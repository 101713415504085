import { ReactComponent as AnalyticsIcon } from "./assets/img/analytics.svg";
import { ReactComponent as DashboardIcon } from "../src/assets/img/dashboard.svg";
import { ReactComponent as VisibilityIcon } from "../src/assets/img/visibility.svg";
import { ReactComponent as AddIcon } from "../src/assets/img/library_add.svg";
import { ReactComponent as ReportsIcon } from "../src/assets/img/description.svg";
import { ReactComponent as SettingsIcon } from "../src/assets/img/settings.svg";
import { ReactComponent as TrialsIcon } from "../src/assets/img/TrialsIcon.svg";
import { ReactComponent as HelpSupportIcon } from "../src/assets/img/helpSupport.svg";
import { ExitToApp } from "@mui/icons-material";
// import ReportIcon from 
import AbcIcon from '@mui/icons-material/Abc';
import Analytics from "./pages/analytics/Analytics";
import View from "./pages/view/View";
import AddDetails from "./pages/adddetails/AddDetails";
import Reports from "./pages/reports/Reports";
import Settings from "./pages/settings/Settings";
import LogoutButton from "./pages/logout/logout";
import AnnualReport from "./pages/reports/Tabs/AnnualReport";
import CustomReport from "./pages/reports/Tabs/CustomReport";
import Business from "./pages/business/business";
import LocationCards from "./pages/business/LocationCards";
import PlantCards from "./pages/business/PlantCards";
import ProcessingPlantCards from "./pages/business/ProcessingPlantCards";
import App from "./pages/analytics/Analytics";

const currentUser = localStorage.getItem("user");
const user = JSON.parse(currentUser);

const Routes = {
  mainRoutes: user ? [
    {
      path: "/business",

      component: Business,


    },

    {
      path: "/plants/:LocationId",
      component: PlantCards,
      render: ({ match }) => <App id={match.params.id} />,
    },
    {
      path: "/processing_plant/:processPlantId",
      component: ProcessingPlantCards,
      render: ({ match }) => <App id={match.params.id} />,
    },
    {
      path: "/analytics/:plantId",
      name: "Dashboard",
      component: App,
      icon: DashboardIcon,
      render: ({ match }) => <App id={match.params.id} />,
    },
    {
      path: "/view",
      name: "Inspections",
      component: View,
      icon: TrialsIcon,
    },

    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      icon: ReportsIcon,
      // render: ({ match }) => <App id={match.params.id} />,
    },
    {
      path: "/AnnualReport",
      component: AnnualReport,

    },
    {
      path: "/customreport",
      component: CustomReport,

    },
    user.role_id !== 2 && {
      path: "/settings",
      name: "Settings",
      component: Settings,
      icon: SettingsIcon,
    },
    {
      path: "/helpandSupport",
      name: "Help&Support",
      component: AddDetails,
      icon: HelpSupportIcon,
    },
    {
      path: "/Logout", // Example path for logout
      name: "Logout",
      component: LogoutButton,
      icon: ExitToApp, // Use the logout icon from MUI
    },
    {
      path: "/location/:businessId",
      component: LocationCards,
      // Example path for logout


      // Use the logout icon from MUI
    },
  ].filter(Boolean) :
    [
      {
        path: "/business",

        component: Business,


      },

      {
        path: "/plants/:LocationId",
        component: PlantCards,
        render: ({ match }) => <App id={match.params.id} />,
      },
      {
        path: "/processing_plant/:processPlantId",
        component: ProcessingPlantCards,
        render: ({ match }) => <App id={match.params.id} />,
      },
      {
        path: "/analytics/:plantId",
        name: "Dashboard",
        component: App,
        icon: DashboardIcon,
        render: ({ match }) => <App id={match.params.id} />,
      },
      {
        path: "/view",
        name: "Inspections",
        component: View,
        icon: TrialsIcon,
      },

      {
        path: "/reports",
        name: "Reports",
        component: Reports,
        icon: ReportsIcon,
        // render: ({ match }) => <App id={match.params.id} />,
      },
      {
        path: "/AnnualReport",
        component: AnnualReport,

      },
      {
        path: "/customreport",
        component: CustomReport,

      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        icon: SettingsIcon,
      },
      {
        path: "/helpandSupport",
        name: "Help&Support",
        component: AddDetails,
        icon: HelpSupportIcon,
      },
      // {
      //   path: "/Login", // Example path for logout
      //   name: "Logout",
      //   component: Logout,
      //   icon: ExitToApp, // Use the logout icon from MUI
      // },
      {
        path: "/location/:businessId",
        component: LocationCards,
        // Example path for logout


        // Use the logout icon from MUI
      },
    ]
};
export default Routes;