import React, { useState,useEffect } from 'react';
import { useRecoilState } from 'recoil';

import {viewedReportDataState} from '../../../../recoil/atoms';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Overview = ({ formData, setFormData}) => {
  
  const { OverviewValue } = formData;
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
  const [initialOverviewData, setInitialOverviewData] = useState('');
  useEffect(() => {
    if (viewedReportData.overview !== undefined && viewedReportData.overview !== initialOverviewData) {
      setInitialOverviewData(viewedReportData.overview);
      setFormData({ ...formData, OverviewValue: viewedReportData.overview });
    }
  }, [viewedReportData.overview,initialOverviewData, formData, setFormData]);

  const handleEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, overview:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, OverviewValue:content });
    
  };
  
  const editorConfig = {
   
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
      // Add other configuration options as needed
    
  };
return (
    <div>
      <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.overview !== undefined ? viewedReportData.overview : OverviewValue}
                onChange={(event, editor) => {
                    handleEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
    </div>
  );
};

export default Overview;








// import React, { useState,useEffect } from 'react';
// import { useRecoilState } from 'recoil';
// import { Button, TextField, RadioGroup, FormControlLabel, Radio,TextareaAutosize,Container,Textarea} from '@mui/material';
// import {viewedReportDataState} from '../../../../recoil/atoms';
// const Overview = ({ formData, setFormData}) => {
  
//   const { OverviewValue } = formData;
//   const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);

//   useEffect(() => {
//     if (viewedReportData.overview !== OverviewValue) {
//       setFormData({ ...formData, OverviewValue: viewedReportData.overview });
//     }
//   }, [viewedReportData.overview, OverviewValue]);

//   const handleOverviewChange = (event) => {
//     const updatedViewedReportData = { ...viewedReportData, overview: event.target.value };
//     setViewedReportData(updatedViewedReportData);
//     setFormData({ ...formData, OverviewValue: event.target.value });
//   };
  
// return (
//     <div>
//       <Container style={{  bottom: 0, left: 0, right: 0, padding: '16px'}}>
//       <form >
        
//       <TextareaAutosize
     
//             style={{
//               flex: '1',
//               resize: 'none',
//               width: '100%',
//               minHeight: '100%',
//               border: 'none',
//               borderRadius: '4px',
//               padding: '10px',
//               backgroundColor:'#f2f2f2',
//             }}
//             value={OverviewValue}
//             onChange={handleOverviewChange}
//           />
    
//       </form>
//       </Container>
//     </div>
//   );
// };

// export default Overview;