import {React} from 'react';
import { Outlet  } from 'react-router-dom';


import TopAppBar from '../Components/drawer/TopAppBar';
import LeftDrawer from '../Components/drawer/LeftDrawer';
import { useState } from 'react';

export default function NisTemplate() {
 
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  
  
  const toggleDrawerOpen = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
    <div style={{ display: 'flex' }}>
      <TopAppBar toggleDrawerOpen={toggleDrawerOpen} open={isDrawerOpen}/>
      <LeftDrawer toggleDrawerOpen={toggleDrawerOpen} open={isDrawerOpen}/>
      
      <main
     
        id="nis-main-element"
        style={{

          flexGrow: 1,

          minHeight: 'calc(100vh - 70px)',
          
          height: 'calc(100vh - 70px)',

          marginTop: '70px',

          padding:'20px',
          width: isDrawerOpen ? 'calc(100% - 220px)' : 'calc(100% - 70px)',
        }}
      >
        <Outlet />
      </main>
    </div>
    </div>
  );
}