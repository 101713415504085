import React, { useState,useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { viewedReportDataState} from '../../../../recoil/atoms';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Conclusion = ({ formData, setFormData}) => {
  const { ConclusionValue } = formData;
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
  const [initialConclusionData, setInitialConclusionData] = useState('');
  useEffect(() => {
    if (viewedReportData.conclusion !== undefined && viewedReportData.conclusion !== initialConclusionData) {
      setInitialConclusionData(viewedReportData.conclusion);
      setFormData({ ...formData, ConclusionValue: viewedReportData.conclusion });
    }
  }, [viewedReportData.conclusion,initialConclusionData, formData, setFormData]);

  const handleEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, conclusion:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, ConclusionValue:content });
    
  };
  const editorConfig = {
   
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
     
   
  };
  return (
    <div>
  <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.conclusion !== undefined ? viewedReportData.conclusion : ConclusionValue}
                onChange={(event, editor) => {
                    handleEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
  </div>
  );
};

export default Conclusion;








// import React, { useState,useEffect } from 'react';
// import { Button, TextField, RadioGroup, FormControlLabel, Radio,TextareaAutosize,Container} from '@mui/material';
// import { useRecoilState } from 'recoil';
// import { viewedReportDataState} from '../../../../recoil/atoms';
// const Conclusion = ({ formData, setFormData}) => {
//   const { ConclusionValue } = formData;
//   const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
//   useEffect(() => {
//     if (viewedReportData.conclusion !== ConclusionValue) {
//       setFormData({ ...formData, ConclusionValue: viewedReportData.conclusion });
//     }
//   }, [viewedReportData.conclusion, ConclusionValue]);
//   const handleConclusionChange = (event) => {
//     const updatedViewedReportData = { ...viewedReportData, conclusion: event.target.value };
//     setViewedReportData(updatedViewedReportData);
//     setFormData({ ...formData, ConclusionValue: event.target.value });
//   };
//   return (
//     <div>
//     <Container  style={{  bottom: 0, left: 0, right: 0, padding: '16px'}}>
//       <form>
//     <TextareaAutosize
//           style={{
//             flex: '1',
//             resize: 'none',
//             width: '100%',
//             minHeight: '100%',
//             border:'none',
//             borderRadius: '4px',
//             padding: '10px',
//             backgroundColor:'#f2f2f2',
//           }}
//           value={ConclusionValue}
//            onChange={handleConclusionChange}
//         />
//     </form>
//     </Container>
//   </div>
//   );
// };

// export default Conclusion;