import React, { useState, useEffect } from 'react';
import { Button, Divider, Tab, Tabs, Typography, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { activeStep, viewedReportDataState, plant, reportName, selectedCreatedByState, reportboolean } from '../../../recoil/atoms';
import FlashMemo from './flashmemo/FlashMemo';
import Objective from './objective/Objective';
import Introduction from './introduction/Introduction';
import Overview from './overview/Overview';
import WorkDes from './workdescription/WorkDes';
import Results from './result/Results';
import AreaDes from './areadescription/AreaDes';
import Conclusion from './conclusion/Conclusion';
import axios from 'axios';
import configParam from '../../../config';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const tabComponents = [
  FlashMemo,
  Objective,
  Introduction,
  Overview,
  WorkDes,
  Results,
  AreaDes,
  Conclusion,
];
const access_token = localStorage.getItem("accesstoken");
const AnnualReport = (reportData) => {
  const [selectedTab, setSelectedTab] = useRecoilState(activeStep);
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);

  const navigate = useNavigate();
  const location = useLocation();
  const selectedYear = location.state?.selectedYear || '';

  const [userdet, setUserdet] = useState({});

  // const [plantName,_] = useRecoilState(plant);
  const datauser = localStorage.getItem("user_id")
  let data = localStorage.getItem('user');
  let User = JSON.parse(data);
  const plantName = datauser != null ? datauser : User.plant_id[0]

  const [FlashMemoData, setFlashMemoData] = useState({
    FlashMemoValue: '',
  });
  const [ObjectiveData, setObjectiveData] = useState({
    title: '',
    n_gie: '',
    objective: '',
    main_conclusion: '',
    next_step: '',

  });
  const [AreaDescriptionData, setAreaDescriptionData] = useState({
    from: '',
    to: '',
    copy: '',
    date: '',

    reference: '',
    confidentiality: '',
  });
  const [ConclusionData, setConclusionData] = useState({
    ConclusionValue: '',
  });
  const [IntroductionData, setIntroductionData] = useState({
    IntroValue: 'SGR India team has developed a competency on Ni inspection in Q1 of 2018 approved by DTI. Thereon, SGR India team has carried out eleven Ni inspection in India,Poland, Egypt and China.The aim of Ni inspection activities is to locate and eliminate high Ni installation partsthroughout SG Floats. By measuring the Ni content in metallic installation which comes indirect or indirect contact with cullet and raw materials using portable XRF. It should beserve to a factory involved, to establish a planning to change parts with high Nickel content,in order to reduce NiS defect (risk of breakage of the glass sheet).NICKEL INSPECTION SGR India team is the part of global Ni inspection team and will carried out Ni inspection asper required by DTI. CNM float line in India has requested to carried out Ni inspection as per DTI guidelines.',
  });
  const [OverviewData, setOverviewData] = useState({
    OverviewValue: '',
  });
  const [ResultData, setResultData] = useState({
    ResultValue: '',
  });
  const [WorkData, setWorkData] = useState({
    WorkDesValue: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [alertMessage] = useState('')


  const [nextReport, setnextReport] = useRecoilState(reportboolean)


  const [repName, setReportName] = useRecoilState(reportName);
  const [selectedCreatedBy, setSelectedCreatedBy] = useRecoilState(selectedCreatedByState); // To store the selected options

  // To store the selected options



  const ApiURL = configParam.API_URL;

  const tabStyles = {
    fontSize: '16px',
    fontWeight: 400,
    flex: 1,
    color: '#808080',
  };
  const activeTabStyles = {
    fontSize: '16px',
    fontWeight: 500,
    flex: 1,
    color: 'black',
  };


  useEffect(() => {

    if (!viewedReportData.id) {
      setViewedReportData({})
      setFlashMemoData({ FlashMemoValue: '' });
      setObjectiveData({
        title: '',
        n_gie: '',
        objective: '',
        main_conclusion: '',
        next_step: '',
      });
      setConclusionData({ ConclusionValue: '' })
      setAreaDescriptionData({
        from: '',
        to: '',
        copy: '',
        date: '',

        reference: '',
        confidentiality: '',
      })
      setOverviewData({ OverviewValue: '' })
      setWorkData({ WorkDesValue: '' })
      setIntroductionData({ IntroValue: '' })
      setResultData({ ResultValue: '' })


      setnextReport(true)
    }
    else {
      const reportId = viewedReportData.id;
      axios.get(`${ApiURL}/get_reportsbyId/${reportId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,

        }
      }
      )
        .then((response) => {
          const updatedReportData = {
            ...response.data[0],
            reportType: reportData.reportType,
            year: reportData.year || '',
            created_by_filter: response.data[0].created_by_filter || []
          };

          setViewedReportData(updatedReportData);

          setConclusionData({ ConclusionValue: updatedReportData.conclusion || '' })
          setOverviewData({ OverviewValue: updatedReportData.overview || '' })
          setResultData({ ResultValue: updatedReportData.result || '' })
          setWorkData({ WorkDesValue: updatedReportData.workDescription || '' })

        })
        .catch((error) => {
          console.error('Error fetching report data:', error);
        });
    }

  }, [viewedReportData.id, reportData]);


  useEffect(() => {
    if (viewedReportData.flashMemoId !== undefined) {
      if (typeof viewedReportData.flashMemoId === 'string') {
       
        if (viewedReportData.flashMemoId.trim() !== '') {
          // Check if the string is not empty or only whitespace
          try {
           
            const areaContentsObject = JSON.parse(viewedReportData.flashMemoId);

            // Create a new object to store the key-value pairs
            const keyValuePairs = {};

            // Iterate through the keys and extract key-value pairs
            for (const key in areaContentsObject) {
              if (areaContentsObject.hasOwnProperty(key)) {
                const value = areaContentsObject[key];
                keyValuePairs[key] = value;
              }
            }

            setFlashMemoData({ FlashMemoValue: keyValuePairs });
            setViewedReportData((prevData) => ({
              ...prevData,
              flashMemoId: keyValuePairs,
            }));

           
          } catch (error) {
            console.error('Error parsing JSON:', error);
            // Handle the error, e.g., setAreaContents to an empty object or show an error message.
          }
        } else {
          // Handle the case when the string is empty or only whitespace

          setFlashMemoData({ flashMemoId: {} });

        }
      }
    }
  }, [viewedReportData.flashMemoId]);

  useEffect(() => {
    let data = localStorage.getItem("user");
    let User = JSON.parse(data)
    setUserdet(User);
  }, []);
  useEffect(() => {

    if (nextReport === true) {
      setViewedReportData({});
    }

  }, [nextReport]);

  useEffect(() => {
    setIntroductionData({
      IntroValue: 'SGR India team has developed a competency on Ni inspection in Q1 of 2018 approved by DTI. Thereon, SGR India team has carried out eleven Ni inspection in India,Poland, Egypt and China.The aim of Ni inspection activities is to locate and eliminate high Ni installation partsthroughout SG Floats. By measuring the Ni content in metallic installation which comes indirect or indirect contact with cullet and raw materials using portable XRF. It should beserve to a factory involved, to establish a planning to change parts with high Nickel content,in order to reduce NiS defect (risk of breakage of the glass sheet).NICKEL INSPECTION SGR India team is the part of global Ni inspection team and will carried out Ni inspection asper required by DTI. CNM float line in India has requested to carried out Ni inspection as per DTI guidelines.',
    });
  }, []); // Empty dependency array ensures this runs only once on component mount

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

  };


  const handleCancel = () => {
    // Reset the data for the current tab based on the selectedTab value
    setFlashMemoData({ FlashMemoValue: '' })
    setObjectiveData({
      title: '',
      n_gie: '',
      objective: '',
      main_conclusion: '',
      next_step: '',
    })
    setConclusionData({ ConclusionValue: '' })
    setAreaDescriptionData({
      from: '',
      to: '',
      copy: '',
      date: '',

      reference: '',
      confidentiality: '',
    })
    setOverviewData({ OverviewValue: '' })
    setWorkData({ WorkDesValue: '' })
    setIntroductionData({ IntroValue: '' })
    setResultData({ ResultValue: '' })
    setViewedReportData({})
    setSelectedCreatedBy([]);
    setReportName('')
    setnextReport(true)
    navigate(`/reports`)
    setSelectedTab(0)
  };
  const TabComponent = tabComponents[selectedTab];
  const isFirstTab = selectedTab === 0;
  const isLastTab = selectedTab === tabComponents.length - 1;
  const handleValidationChange = (isValid) => {
    setIsFormValid(isValid);
  };

  const handleNext = () => {

    if (!isLastTab) {
      setSelectedTab(selectedTab + 1);
    } else {
      if (isFormValid) {

        const reportType = tabComponents[selectedTab].name;
        const flashMemoData = FlashMemoData.FlashMemoValue;

        const objectiveData = ObjectiveData;
        const introductionData = IntroductionData.IntroValue;
        const overviewData = OverviewData.OverviewValue;
        const workDesData = WorkData.WorkDesValue;
        const resultsData = ResultData.ResultValue;
        const areaDesData = AreaDescriptionData;
        const conclusionData = ConclusionData.ConclusionValue;

       


        const reportData = viewedReportData.id ? {
          id: viewedReportData.id,
          flashMemoData,

          objectiveId: viewedReportData.objectiveId,
          introductionData: viewedReportData.introduction,
          overviewData: viewedReportData.overview,
          areaDescription: viewedReportData.areaDescription,
          workDesData: viewedReportData.workDescription,
          resultsData: viewedReportData.result,
          from: viewedReportData.from,
          to: viewedReportData.to,
          copy: viewedReportData.copy,
          date: viewedReportData.date,
          confidentiality: viewedReportData.confidentiality,
          reference: viewedReportData.reference,
          title: viewedReportData.title,
          n_gie: viewedReportData.n_gie,
          objective: viewedReportData.objective,
          main_conclusion: viewedReportData.main_conclusion,
          next_step: viewedReportData.next_step,
          conclusionData,
          userdet: userdet.id,
          updated_by: userdet.id,
          year: selectedYear,
          plantName,
          report_name: repName,
          created_by_filter: selectedCreatedBy,



        } : {

          flashMemoData,
          objectiveData,
          introductionData,
          overviewData,
          workDesData,
          resultsData,
          areaDesData,
          conclusionData,
          userdet: userdet.id,
          updated_by: null,
          year: selectedYear,
          plantName,
          repName,
          selectedCreatedBy,

        };

        if (viewedReportData.id) {

          // Update the existing report
          axios.post(ApiURL + '/update_flash', reportData, {
            headers: {
              Authorization: `Bearer ${access_token}`,

            }
          }
          )
            .then((flashresponse) => {
              if (flashresponse.status === 200) {
                axios.post(ApiURL + '/update_objective', reportData, {
                  headers: {
                    Authorization: `Bearer ${access_token}`,

                  }
                }
                )
                  .then((Objresponse) => {
                    if (Objresponse.status === 200) {
                      axios
                        .post(`${ApiURL}/update_report/${viewedReportData.id}`, reportData, {
                          headers: {
                            Authorization: `Bearer ${access_token}`,

                          }
                        }
                        )
                        .then((response) => {
                          if (response.status === 200) {
                            setIsUpdating(true);
                            setSnackbarOpen(true);
                            navigate(`/reports`)
                            setSelectedTab(0)

                          }
                        })
                        .catch((error) => {
                          console.error('Failed to update data:', error);

                        });
                    }

                  })
                  .catch((error) => {
                    console.error('Failed to update data:', error);

                  });
              }
            })
            .catch((error) => {
              console.error('Failed to update data:', error);

            });

        }
        else {
          axios.post(ApiURL + '/create_flash', reportData, {
            headers: {
              Authorization: `Bearer ${access_token}`,

            }
          }
          )
            .then((flashresponse) => {
              reportData.areaDesDataId = flashresponse.data.identifiers[0].id;
              axios.post(ApiURL + '/create_objective', reportData, {
                headers: {
                  Authorization: `Bearer ${access_token}`,

                }
              }
              )
                .then((Objresponse) => {
                  reportData.objectiveDataId = Objresponse.data.identifiers[0].id;
                  axios.post(ApiURL + '/create_report', reportData, {
                    headers: {
                      Authorization: `Bearer ${access_token}`,

                    }
                  }
                  )
                    .then((response) => {
                      if (response.status === 200) {

                        setIsUpdating(false);
                        setSnackbarOpen(true);
                        navigate(`/reports`);
                        setSelectedTab(0)



                      }
                    }).catch((error) => {
                      console.error('Failed to save data:', error);
                    });

                })
                .catch((error) => {
                  console.error('Failed to save data:', error);

                });
            })
            .catch((error) => {
              console.error('Failed to save data:', error);

            });


        }

        setFlashMemoData({ FlashMemoValue: '' })
        setObjectiveData({
          title: '',
          n_gie: '',
          objective: '',
          main_conclusion: '',
          next_step: '',
        })
        setConclusionData({ ConclusionValue: '' })
        setAreaDescriptionData({
          from: '',
          to: '',
          copy: '',
          date: '',
          reference: '',
          confidentiality: ''
        })
        setOverviewData({ OverviewValue: '' })
        setWorkData({ WorkDesValue: '' })
        setIntroductionData({ IntroValue: '' })
        setResultData({ ResultValue: '' })
        setViewedReportData({})
        setnextReport(true)
        setSelectedCreatedBy([])
        setReportName('')
      }

    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  const handleBack = () => {
    if (!isFirstTab) {
      setSelectedTab(selectedTab - 1);
    }
  };
  useEffect(() => {
   // This will log the state when it's updated
  }, [IntroductionData]);
  useEffect(() => {

  }, [IntroductionData.IntroValue]);
  const tabNames = {
    6: 'Area Description',
    1: 'Content',
    2: 'Introduction',
    3: 'Overview',
    4: 'Work Description',
    5: 'Results',
    0: 'Flash Memo',
    7: 'Conclusion',
  };
  return (
    <div>
      <div className='desktop-view'>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item >
            <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 20 }}>
              Reports Documentation
            </Typography>
          </Grid>
          <Grid item >
            <Button style={{ marginRight: '8px', color: '#203468' }} onClick={handleCancel}>Cancel</Button>
            {!isFirstTab && (
              <Button variant="outlined" style={{ borderColor: '#203468', color: '#203468' }} onClick={handleBack}>
                Back
              </Button>
            )}
            <Button
              variant={isLastTab ? 'contained' : 'outlined'}
              style={{
                marginLeft: '8px',
                backgroundColor: '#203468', // Set the background color to #203468 for both Next and Submit
                color: '#fff', // Set the text color to white (#fff) for both Next and Submit
              }}
              onClick={handleNext}
              disabled={!isFormValid}
            >
              {isLastTab ? 'Submit' : 'Next'}
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ margin: '16px 0' }} />
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { backgroundColor: 'black' } }}
          style={{ width: '100%', justifyContent: 'space-between', position: 'relative' }}
        >
          <Tab label="Flashmemo" style={selectedTab === 6 ? activeTabStyles : tabStyles} />
          <Tab label="Content" style={selectedTab === 1 ? activeTabStyles : tabStyles} />
          <Tab label="Introduction" style={selectedTab === 2 ? activeTabStyles : tabStyles} />
          <Tab label="Overview" style={selectedTab === 3 ? activeTabStyles : tabStyles} />
          <Tab label="Work Des" style={selectedTab === 4 ? activeTabStyles : tabStyles} />
          <Tab label="Results" style={selectedTab === 5 ? activeTabStyles : tabStyles} />
          <Tab label="Area Des" style={selectedTab === 0 ? activeTabStyles : tabStyles} />
          <Tab label="Conclusion" style={selectedTab === 7 ? activeTabStyles : tabStyles} />
          <div
            style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              height: '1px',
              backgroundColor: '#0000001A',
              zIndex: '1',
            }}
          ></div>
        </Tabs>
        {viewedReportData && (
          <React.Fragment>
            {selectedTab === 6 && <FlashMemo formData={FlashMemoData} setFormData={setFlashMemoData} />}
            {selectedTab === 1 && <Objective formData={ObjectiveData} setFormData={setObjectiveData} viewedReportData={viewedReportData} />}
            {selectedTab === 2 && IntroductionData !== null && (
              <Introduction
                formData={IntroductionData}
                setFormData={setIntroductionData}
                viewedReportData={viewedReportData}
              />
            )}

            {selectedTab === 3 && <Overview formData={OverviewData} setFormData={setOverviewData} viewedReportData={viewedReportData} />}
            {selectedTab === 4 && <WorkDes formData={WorkData} setFormData={setWorkData} viewedReportData={viewedReportData} />}
            {selectedTab === 5 && <Results formData={ResultData} setFormData={setResultData} viewedReportData={viewedReportData} />}
            {selectedTab === 0 && <AreaDes formData={AreaDescriptionData} setFormData={setAreaDescriptionData} viewedReportData={viewedReportData} onValidationChange={handleValidationChange} />}
            {selectedTab === 7 && <Conclusion formData={ConclusionData} setFormData={setConclusionData} viewedReportData={viewedReportData} />}
          </React.Fragment>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="success"
            onClose={handleSnackbarClose}
          >
            Annual Report {isUpdating ? 'updated' : 'created'} successfully
          </MuiAlert>

        </Snackbar>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={25000} // Adjust the duration as needed
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="success"
            onClose={handleSnackbarClose}
          >
            {alertMessage}
          </MuiAlert>

        </Snackbar>
      </div>
      <div className='mobile-view'>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#203468',
                  color: '#fff',
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {selectedTab + 1}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.55)',
                  fontSize: '14px',
                }}
              >
                Step {selectedTab + 1} / 8
              </div>
              <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '16px' }}>
                Reports Documentation - {tabNames[selectedTab]}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Divider style={{ margin: '16px 0' }} />
        {viewedReportData && (
          <React.Fragment>
            {selectedTab === 6 && <FlashMemo formData={FlashMemoData} setFormData={setFlashMemoData} />}
            {selectedTab === 1 && <Objective formData={ObjectiveData} setFormData={setObjectiveData} />}
            {selectedTab === 2 && IntroductionData !== null && (
              <Introduction
                formData={IntroductionData}
                setFormData={setIntroductionData}
                viewedReportData={viewedReportData}
              />
            )}

            {selectedTab === 3 && <Overview formData={OverviewData} setFormData={setOverviewData} viewedReportData={viewedReportData} />}
            {selectedTab === 4 && <WorkDes formData={WorkData} setFormData={setWorkData} viewedReportData={viewedReportData} />}
            {selectedTab === 5 && <Results formData={ResultData} setFormData={setResultData} viewedReportData={viewedReportData} />}
            {selectedTab === 0 && <AreaDes formData={AreaDescriptionData} setFormData={setAreaDescriptionData} viewedReportData={viewedReportData} />}
            {selectedTab === 7 && <Conclusion formData={ConclusionData} setFormData={setConclusionData} viewedReportData={viewedReportData} />}
          </React.Fragment>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="success"
            onClose={handleSnackbarClose}
          >
            Annual Report {isUpdating ? 'updated' : 'created'} successfully
          </MuiAlert>

        </Snackbar>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={25000} // Adjust the duration as needed
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="success"
            onClose={handleSnackbarClose}
          >
            {alertMessage}
          </MuiAlert>

        </Snackbar>
        <Divider style={{ margin: '16px 0' }} />
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item >
            <Button style={{ marginRight: '8px', color: '#203468' }} onClick={handleCancel}>Cancel</Button>
            {!isFirstTab && (
              <Button variant="outlined" style={{ borderColor: '#203468', color: '#203468' }} onClick={handleBack}>
                Back
              </Button>
            )}
            <Button
              variant={isLastTab ? 'contained' : 'outlined'}
              style={{
                marginLeft: '8px',
                backgroundColor: '#203468', // Set the background color to #203468 for both Next and Submit
                color: '#fff', // Set the text color to white (#fff) for both Next and Submit
              }}
              onClick={handleNext}
            >
              {isLastTab ? 'Submit' : 'Next'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AnnualReport;