import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,

  Typography,

  IconButton,

  Grid,

  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Tooltip
} from '@mui/material';
import Routes from '../../routes';

import { useRecoilState, useRecoilValue } from 'recoil';

import { menuList } from '../../recoil/atoms';
import { Link as RouterLink } from 'react-router-dom';
import { plantId, userPlantState, userLocationState } from '../../recoil/atoms';
import { useLocation } from 'react-router-dom';
import { ReactComponent as AppsIcon } from '../../assets/img/apps.svg';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as HomeIcon } from '../../assets/img/HomeIcon.svg';



export default function TopAppBar({ pathname, toggleDrawerOpen, open }) {
  // Create or import your theme object

  const userPlant = useRecoilValue(userPlantState);
  const userLocation = useRecoilValue(userLocationState);
  const [, setMenu] = useRecoilState(menuList);
  const [, setSelectedItem] = useState(null);
  const menu = useRecoilValue(menuList);
  const [PlantId, _] = useRecoilState(plantId);
  const location = useLocation();
  const [userDet, setUserDet] = useState({});
  const [, setLocationDet] = useState({});
  const [plantDet, setPlantDet] = useState('');
  const [breadcrumb, setBreadcrumb] = useState('');
  const navigate = useNavigate();
 

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter(path => path);
    const hasMultiplePaths = pathnames.length > 1;
    const currentBreadcrumb = hasMultiplePaths ? pathnames[pathnames.length - 2] : pathnames[0];
    setBreadcrumb(currentBreadcrumb);
  }, [location]);



  useEffect(() => {
    let data = localStorage.getItem('user');
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);
  useEffect(() => {
    setLocationDet(userLocation || {});
  }, [userLocation]);
  useEffect(() => {
    setPlantDet(userPlant)
  }, [userPlant]);


  const changeMenu = (name) => {
    setMenu(name);
    setSelectedItem(name);
  };

  function formatBusinessName(name) {
    const trimmedName = name.trim(); // Remove extra spaces

    if (trimmedName.length <= 3) {
      return trimmedName.toUpperCase();
    }

    return trimmedName.toUpperCase();
  }


  const handleDashboardClick = () => {

    navigate(`/analytics/${PlantId}`);
  };
  const getBreadcrumbLabel = () => {
    switch (breadcrumb) {
      case 'view':
        return 'Inspection';
      case 'analytics':
        return 'Dashboard';
      default:
        return formatBusinessName(breadcrumb);
    }
  };

  function getListItemSet(text, index) {
    // Determine if the current item is selected
    const isSelected = menu === text.name;

    // Determine color scheme based on isSelected
    const colorScheme = isSelected ? { textColor: '#000', backgroundColor: '#f2f2f2', iconColor: '#000' } : { textColor: '#757575', backgroundColor: '', iconColor: '#757575' };
    const { textColor, backgroundColor, iconColor } = colorScheme;

    // Early return if text.icon is falsy
    if (!text.icon) return null;

    return (
      <NavLink key={index} style={{ textDecoration: 'none' }} to={text.path}>
        <ListItem
          key={text}
          disablePadding
          sx={{
            display: 'block',
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 1,
            paddingBottom: 1,
            marginBottom: 1,
            backgroundColor: backgroundColor,
          }}
          style={{ backgroundColor: isSelected ? '#008C0000' : '' }}
          onClick={() => changeMenu(text.name)}
        >
          <ListItemButton
            sx={{
              minHeight: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <SvgIcon style={{ color: iconColor, marginRight: 12 }}>
              <Tooltip title={text.name} placement="right">
                <text.icon />
              </Tooltip>
            </SvgIcon>
            {open && (
              <ListItemText
                className="Drawer-ListItemText"
                primary={text.name === 'Logout' ? `${text.name} - ${userDet.name}` : text.name}
                sx={{ opacity: 1 }}
                style={{ color: textColor }}
              />
            )}
            <ListItemText className="Dashboardpage" primary={handleDashboardClick} />
          </ListItemButton>
        </ListItem>
      </NavLink>
    );
  }


  const handleToggleDrawer = () => {
    // Toggle the 'open' state of the drawer
    toggleDrawerOpen(open);
  };
  const renderHomeContent = () => {

    return (
      <>
        <RouterLink to="/business">
          <Typography style={{ color: '#1C1C1C' }}>Home</Typography>
        </RouterLink>
        <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
        {userDet && (
          <RouterLink to={`/location/${userDet.business_id}`}>
            <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(userDet.location)}</Typography>
          </RouterLink>
        )}
        {userDet && <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>}
        {userDet && plantDet ? (
          <RouterLink to={`/plants/${userDet.location_id}`}>
            <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(plantDet)}</Typography>
          </RouterLink>
        ) : (<RouterLink to={`/plants/${userDet.location_id}`}>
          <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName((userDet.plant_names.find(plant => plant.id === userDet.plant_id[0])).name)}</Typography>
        </RouterLink>)}
        {userDet && <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>}
      </>
    );



  };
  const drawer = (
    <div>
      <List>
        {Routes.mainRoutes
          .filter(text => text.name !== 'Settings' || userDet.role_id === 1 || userDet.role_id === 3)
          .map(getListItemSet)}
      </List>
    </div>
  );
  const drawerWidth = open ? '180px' : '0';
  const justifyContentValue = open ? 'initial' : 'center';
  const mobileDrawer = (

    <Drawer
      variant="persistent"
      style={{
        whiteSpace: 'nowrap',
        position: 'fixed',
        width: drawerWidth,
        left: '0',
        top: '0',
        height: '100%',
        zIndex: 9999,
      }}
      open={open}
    >

      <List>
        <ListItem disablePadding>

          <ListItemButton
            sx={{
              minHeight: 58,
              justifyContent: justifyContentValue,
              px: 2.5
            }}
            onClick={toggleDrawerOpen}
          >
            <IconButton
              color="inherit"
              aria-label="toggle drawer"
              edge="start"
              sx={{ display: 'block', cursor: 'pointer', width: 48, height: 48 }}
            >
              <AppsIcon />
            </IconButton>
            {open && <ListItemText primary="NiFi Application" sx={{ opacity: 1 }} style={{ color: 'black' }} />}
          </ListItemButton>
        </ListItem>
        {drawer}
        {/* SIDEBAR LIST OF ICONS BOTTOM*/}
        <List>
          {Routes.mainRoutes
            .filter(text => text.footer && text.iconFlag)
            .map((text, index) => getListItemSet(text, index))}
        </List>
      </List>
    </Drawer>
  );
  const shouldRenderComponent = userDet.role_id !== undefined && userDet.role_id !== null;
  const leftValue = open ? '220px' : '70px';
  return shouldRenderComponent ? (

    <div>

      <div className="desktop-view">

        <AppBar
          elevation={0}

          sx={{
            backgroundColor: '#ffffff',
            padding: '3px',
            boxShadow: '0px 2px 5px #00000026',
            left: leftValue, // Set the width to 1708px
            width: '100%',
            height: '72px',
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>NIS</title>
          </Helmet>
          <Toolbar variant="dense" disableGutters style={{ minHeight: 48 }}>
            <Grid container style={{ padding: '20px 0 10px ' }} justifyContent={'space-between'} alignItems={'center'}>
              <Box sx={{ marginLeft: { xs: '85px', sm: '85px', md: '85px', xl: '85px' } }}>
                <div style={{ display: 'flex' }}>
                  <HomeIcon style={{ marginRight: '5px' }} />
                  {renderHomeContent()}

                  <Typography style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 600 }}>{formatBusinessName(breadcrumb)}</Typography>
                </div>
              </Box>

            </Grid>
          </Toolbar>
        </AppBar>
      </div>
      <div className="mobile-view">
        <AppBar
          elevation={0}
          sx={{
            backgroundColor: '#ffffff',
            padding: '3px',
            boxShadow: '0px 2px 5px #00000026',
            left: '0px', // Set the width to 1708px
            width: '100%',
            height: '72px',


          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>NIFI</title>
          </Helmet>
          <Toolbar variant="dense" disableGutters style={{ minHeight: 48 }}>

            <Box sx={{ marginLeft: { xs: '160px', sm: '85px', md: '85px', xl: '85px' } }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>

                <IconButton
                  color="inherit"
                  aria-label="toggle drawer"
                  edge="start"
                  sx={{
                    display: 'block',
                    cursor: 'pointer',
                    width: 48,
                    height: 48,
                    position: 'absolute', // Position the icon absolutely within the Appbar
                    left: '2px', // 
                    marginTop: '45px',
                  }}
                  onClick={handleToggleDrawer} // Handle toggle drawer when AppsIcon is clicked
                >
                  <AppsIcon />
                </IconButton>



              </div>
              <Grid item marginRight={3}>
                <Typography style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 600, marginTop: 10 }}>
                  {getBreadcrumbLabel()}
                </Typography>
              </Grid>
            </Box>

          </Toolbar>
          {mobileDrawer}
        </AppBar>
      </div>
    </div>
  ) : null;
}
