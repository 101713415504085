import React, { useState,useEffect} from 'react';

import { useRecoilState } from 'recoil';
import { viewedReportDataState} from '../../../../recoil/atoms';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Results = ({ formData, setFormData }) => {
  
  const { ResultValue } = formData;
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
  const [initialResultData, setInitialResultData] = useState('');
  useEffect(() => {
    if (viewedReportData.result !== undefined && viewedReportData.result !== initialResultData) {
      setInitialResultData(viewedReportData.result);
      setFormData({ ...formData, ResultValue: viewedReportData.result });
    }
  }, [viewedReportData.result,initialResultData, formData, setFormData]);

  const handleEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, result:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, ResultValue:content });
    
  };
  const editorConfig = {
   
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
      // Add other configuration options as needed
    
  };
  return (
    <div>
      <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.result !== undefined ? viewedReportData.result : ResultValue}
                onChange={(event, editor) => {
                    handleEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
      
    </div>
  );
};

export default Results;










