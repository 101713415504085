import React, { useState, useEffect } from "react";

import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Grid,
  Snackbar,
  IconButton,
  InputLabel,
  Card, CardContent,  Menu,Typography,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import MoreVertIcon from '@mui/icons-material/MoreVert';




import { ReactComponent as EditIcon } from "../../assets/img/Edit.svg";

import { ReactComponent as DeleteIcon } from "../../assets/img/Delete.svg";

import { useRecoilState,useRecoilValue } from 'recoil';

import AddIcon from "@mui/icons-material/Add";

import AddArea from "./AddArea";

import AddUser from "./AddUser";
import AddPlant from "./AddPlant";
import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import configParam from "../../config";

import { UserSettings } from '../../recoil/atoms';
const cardStyle = {
  width: '100%',
  height: '168px',
  marginBottom: '10px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: '1px solid  #E0E0E0',
  marginRight: '18px', // Add margin to the right of each card
  

};
const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
};
const topRightCornerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '15px',
};

const access_token=localStorage.getItem("accesstoken");

const YearSelectBox = ({ selectedYear, years, onSelectedYearChange }) => {
  
 
  return (
    <FormControl fullWidth>
      <Select
        value={selectedYear}
        onChange={onSelectedYearChange}
        className="select-nis"
        size="small"
      >
        <MenuItem value="">All Years</MenuItem>
        {years.map((year, index) => (
          <MenuItem key={index} value={year.year}>
            {year.year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};



function Settings() {
  const [userDet, setUserDet] = useState({});

  const [activeTab, setActiveTab] = useState("area");


  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentaddedPlants, setCurrentAddedPlants] = useState([]);
  const [addAreaModalOpen, setAddAreaModalOpen] = useState(false);
  const [addPlantModalOpen, setAddPlantModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [currentaddedAreas, setCurrentAddedAreas] = useState([]);
  const [addedAreas, setAddedAreas] = useState([]);
  const [addedPlants,setAddedPlants]=useState([]);
  const [currentaddedUsers, setCurrentAddedUsers] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [iconsVisibleArea, setIconsVisibleArea] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedRowUserData, setSelectedRowUserData] = useState(null);
  const [selectedRowPlantData, setSelectedRowPlantData] = useState(null);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [selectedUserCardData, setSelectedUserCardData] = useState(null);
  const [selectedPlantCardData, setSelectedPlantCardData] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const ApiURL = configParam.API_URL;
  const [areaRows, setAreaRows] = useState([]);
  const [selectedUserYear, setSelectedUserYear] = useState("");
  const [selectedAreaYear, setSelectedAreaYear] = useState("");
  const [menuAnchor, setMenuAnchor] = useState([]); ;
  const [areaFilter, setAreaFilter] = useState("");
  const [uniqueAreas, setUniqueAreas] = useState([]);
  const[userDataSettings,setUserDataSettings]=useRecoilState(UserSettings)
  const [isYearSelected, setIsYearSelected] = useState(false);
 
  const handleOpenMenu = (event, data, index) => {
    const newMenuAnchors = [...menuAnchor];
    newMenuAnchors[index] = event.currentTarget;
    setMenuAnchor(newMenuAnchors);
  };

  // Function to handle closing the menu for a specific card
  const handleCloseMenu = (index) => {
    const newMenuAnchors = [...menuAnchor];
    newMenuAnchors[index] = null;
    setMenuAnchor(newMenuAnchors);
  };

  const tabStyle = (value) => ({
    fontWeight: activeTab === value ? 600 : 500,
    fontSize: activeTab === value ? "20px" : "19px",
    color: activeTab === value ? "#000000" : "#000000CC",
    padding: "0 32px",
  });
  const tabStyleMobile = (value) => ({
    fontWeight: activeTab === value ? 400 : 300,
    fontSize: activeTab === value ? "10px" : "9px",
    color: activeTab === value ? "#000000" : "#000000CC",
    padding: "0 20px",
  });
  useEffect(() => {
    let data = localStorage.getItem("user");
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);
  useEffect(() => {
    if (userDet) {
      setIconsVisibleArea(userDet.role_id === 3);
    }
  }, [userDet]);
  useEffect(()=>{
    let data = localStorage.getItem("user");
    let User = JSON.parse(data)
    
    setUserDet(User);
  },[userDataSettings]);
  
  
  useEffect(() => {
    // Retrieve unique area values from the addedAreas array
    const uniqueAreaValues = [...new Set(addedAreas.map(area => area.plantName))].sort((a, b) => a.localeCompare(b));
   
    setUniqueAreas(uniqueAreaValues);
  }, [addedAreas]);

  const handleAreaFilterChange = (event) => {
    const newAreaFilter = event.target.value;
    setAreaFilter(newAreaFilter);
  };

const filteredAreaRows = areaFilter
? addedAreas.filter((area) =>
    area && area.plantName && area.plantName.toLowerCase().includes(areaFilter.toLowerCase())
  )
: addedAreas;
  useEffect(()=>{
      const filteredAreaRows1 = areaFilter
      ? addedAreas.filter((area) =>
          area && area.plantName && area.plantName.toLowerCase().includes(areaFilter.toLowerCase())
        )
      : addedAreas;
     
  
      setAreaRows(filteredAreaRows1.map((area, index) => ({
        
        id: index + 1,
        location: (area.location||"").toUpperCase(),
        business: (area.business_name || "").toUpperCase(),
       
        plant: (area.plantName || "").toUpperCase(),
        area: (area.area?.charAt(0) || "").toUpperCase() + (area.area || "").slice(1).toLowerCase(),
        description: area.description,
        addedBy: area.name,
        addedOn: area.created_on ? area.created_on.split("T")[0] : "",
      })))
    
    
  },[addedAreas])

  useEffect(()=>{
    
      const filteredAreaRows1 = areaFilter
      ? addedAreas.filter((area) =>
          area && area.plantName && area.plantName.toLowerCase().includes(areaFilter.toLowerCase())
        )
      : addedAreas;
  
  
      setAreaRows(filteredAreaRows1.map((area, index) => ({
        
        id: index + 1,
        location: (area.location||"").toUpperCase(),
        business: (area.business_name||"").toUpperCase() ,
       
        plant: (area.plantName || "").toUpperCase(),
        area: (area.area?.charAt(0) || "").toUpperCase() + (area.area || "").slice(1).toLowerCase(),
        description: area.description,
        addedBy: area.name,
        addedOn: area.created_on ? area.created_on.split("T")[0] : "",
      })))
    
    
  },[areaFilter])

  const [years, setYears] = useState([]);

  const fetchYears = async () => {
    try {
      const response = await axios.get(ApiURL + "/show_user_year",{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
); // Replace with your API endpoint
      setYears(response.data);
    } catch (error) {
      console.error("Error fetching years:", error);
    }
  };

  useEffect(() => {
    fetchYears();
  }, []);

 

  const handleOpenAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };
  useEffect(() => {
    // Fetch user details whenever the user settings tab becomes active
    if (activeTab === "user") {
      getuserDetails();
    }
  }, [activeTab]);
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleAddAreaClick = () => {
    setAddAreaModalOpen(true);
  };
  const handleAddPlantClick=()=>{
    setAddPlantModalOpen(true);
  }
  const handleAddUserClick = () => {
    setAddUserModalOpen(true);
  };

  const handleAddAreaModalClose = () => {
    setSelectedRowData(null);
    setSelectedCardData(null);
    setAddAreaModalOpen(false);
  };
  const handleAddPlantModalClose = () => {
    setSelectedRowPlantData(null);
    setSelectedPlantCardData(null);
    setAddPlantModalOpen(false);
  };
  const handleAddUserModalClose = () => {
    setSelectedRowUserData(null);
    setSelectedUserCardData(null);
    setAddUserModalOpen(false);
  };

  const handleSaveArea = (newArea) => {

    if (selectedRowData) {
    
      const indexes = addedAreas.map((_, index) => index);
      const selectedIndex = indexes.findIndex(
        (area) => area === selectedRowData.id - 1,
      );
      if (selectedIndex !== -1) {
        const updatedAreas = [...addedAreas];
        updatedAreas[selectedIndex] = {
          ...updatedAreas[selectedIndex],
          ...newArea,
        };
        setAddedAreas(updatedAreas);
        setSelectedRowData(null)
        setSelectedCardData(null)
      }
    } 
    else {
      const updatedArea = { ...newArea, id: addedAreas.length + 1 };
      setAddedAreas([...addedAreas, updatedArea]);
      setSelectedRowData(null)
      setSelectedCardData(null)
    }
    setAddAreaModalOpen(false);
  };

  const handleSaveUser = (newUser) => {
    if (selectedRowUserData) {
      const indexes = addedUsers.map((_, index) => index);
      const selectedIndex = indexes.findIndex(
        (user) => user === selectedRowUserData.id - 1,
      );
      if (selectedIndex !== -1) {
        const updatedUsers = [...addedUsers];
        updatedUsers[selectedIndex] = {
          ...updatedUsers[selectedIndex],
          ...newUser,
        };
        setAddedUsers(updatedUsers);
        setSelectedRowUserData(null)
        setSelectedUserCardData(null)
      }
    } else {
     
      const updatedUsers = { ...newUser, id: addedUsers.length + 1 };
      setAddedUsers([...addedUsers, updatedUsers]);
      setSelectedRowUserData(null)
      setSelectedUserCardData(null)
    }
    setAddUserModalOpen(false);
  };
  const handleSavePlant = (newPlant) => {
    if (selectedRowPlantData) {
      const indexes = addedPlants.map((_, index) => index);
      const selectedIndex = indexes.findIndex(
        (plant) => plant === selectedRowPlantData.id - 1,
      );
      if (selectedIndex !== -1) {
        const updatedPlants = [...addedPlants];
        updatedPlants[selectedIndex] = {
          ...updatedPlants[selectedIndex],
          ...newPlant,
        };
        setAddedPlants(updatedPlants);
      }
    } else {
      
      const updatedPlants = { ...newPlant, id: addedPlants.length + 1 };
      setAddedUsers([...addedPlants, updatedPlants]);
    }
    setAddPlantModalOpen(false);
    setSelectedRowPlantData(null);
    setSelectedPlantCardData(null);
  };

    
  const deleteArea = (areaId) => {
  
    const url = ApiURL + "/delete_area"; // Replace with your backend API endpoint to delete area
    axios
      .delete(url, { data: { areaId: areaId },headers: {
        Authorization: `Bearer ${access_token}`,
     
      } }
)
      .then((response) => {
        // Handle successful response
      })
      .catch((error) => {
        // Handle error
        console.error("Failed to delete area:", error);
      });
  };
  const deleteUser = (userId) => {
    const url = ApiURL + "/delete_user"; // Replace with your backend API endpoint to delete area
    axios
      .delete(url, { data: { userId: userId }, headers: {
        Authorization: `Bearer ${access_token}`,
        
      } }
)
      .then((response) => {
        // Handle successful response
      })
      .catch((error) => {
        // Handle error
        console.error("Failed to delete user:", error);
      });
  };
  const deletePlant = (plantId) => {
    const url = ApiURL + "/delete_plant"; // Replace with your backend API endpoint to delete area
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          
        },
        data: {
          plantId: plantId
        }
      })

      .then((response) => {
        // Handle successful response
      })
      .catch((error) => {
        // Handle error
        console.error("Failed to delete user:", error);
      });
  };

  const handleConfirmDelete = () => {
    if (activeTab === "area") {
      if(selectedItem){
       
        const indexes = addedAreas.map((_, index) => index);
        const deleteaddedareas=addedAreas
        const filterareas= deleteaddedareas.filter((area) =>
        area && area.plantName && area.plantName.toLowerCase().includes(areaFilter.toLowerCase())
      )
        const element = filterareas[selectedItem - 1];
        const areaId = element.id;
      
        const filteredAreas = filteredAreaRows.filter(
          (_, index) => index === selectedItem - 1,
        );
       
        const finalfilteredarea=addedAreas.filter((area)=>
        area.id!==filteredAreas[0].id)
      
        setAddedAreas(finalfilteredarea);
        deleteArea(areaId);
        setAlertOpen(true);
  
        setAlertMessage("Area deleted successfully");
       
       
      }
      if(selectedDeleteItem){
        const areaId = selectedDeleteItem.id;
        const filteredAreas = addedAreas.filter(
          (data, index) => data !== selectedDeleteItem,
        );
        setAddedAreas(filteredAreas);
        deleteArea(areaId);
        setAlertOpen(true);
        setAlertMessage("Area deleted successfully");
      }
     
    } 
    else if (activeTab === "plant") {
      if(selectedItem){
        const indexes = addedPlants.map((_, index) => index);

        const element = addedPlants[selectedItem - 1];
        const plantId = element.id;
  
        const filteredPlants = addedPlants.filter(
          (_, index) => index !== selectedItem - 1,
        );
        setAddedPlants(filteredPlants);
        deletePlant(plantId);
        setAlertOpen(true);
  
        setAlertMessage("Plant deleted successfully");
      }
      if(selectedDeleteItem){
        const plantId = selectedDeleteItem.id;
        const filteredPlants = addedPlants.filter(
          (data, index) => data !== selectedDeleteItem,
        );
        setAddedPlants(filteredPlants);
        deletePlant(plantId);
        setAlertOpen(true);
        setAlertMessage("Plant deleted successfully");
      }
     
    } 
    else {
      if(selectedItem){
        const indexes = addedUsers.map((_, index) => index);
      const element = addedUsers[selectedItem - 1];
      const userId = element.id;
      const filteredUsers = addedUsers.filter(
        (_, index) => index !== selectedItem - 1,
      );
      setAddedUsers(filteredUsers);
      deleteUser(userId);
      setAlertOpen(true);

      setAlertMessage("User deleted successfully");
      }
      if(selectedDeleteItem){
        const userId = selectedDeleteItem.id;
        const filteredUsers = addedUsers.filter(
          (data, index) => data !== selectedDeleteItem,
        );
        setAddedUsers(filteredUsers);
        deleteUser(userId);
        setAlertOpen(true);
  
        setAlertMessage("User deleted successfully");
      }
    }

    setConfirmationModalOpen(false);

    setSelectedItem(null);
    setSelectedDeleteItem(null);
  };

  const handleCancelDelete = () => {
    setConfirmationModalOpen(false);

    setSelectedItem(null);
    setSelectedDeleteItem(null);
  };
 
  const userRows = addedUsers.map((user, index) => {
    let plant = [];
    if (user.plant_name !== null && user.plant_name !== undefined && user.plant_name.length > 0) {
      plant = user.plant_name.map((item) => item.toUpperCase());
    }
  
    return {
      id: index + 1,
      sgid: user.sgid,
      name: user.name,
      location: (user.location||"").toUpperCase(),
      email: user.email,
      business: (user.business_name||"").toUpperCase() ,
      role: (user.role_name||"").charAt(0).toUpperCase()+ (user.role_name||"").slice(1),
      plant: plant, // Use the transformed plant array here
      addedBy: user.created_by,
      addedOn: user.created_on ? user.created_on.split("T")[0] : "",
    };
  });
  
  
  
 
  const plantRows = addedPlants.map((area, index) => ({
    id: index + 1,
    location: (area.location || "").toUpperCase(),
    business: (area.business_name|| "").toUpperCase(),
    plant: (area.plantName || "").toUpperCase(),
    addedBy: area.name,
    category:area.category_name
  }));
  const OriginalareaColumns = [
    {
      field: "id",
      headerName: "S.No",
      minWidth: 170,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "business",
      headerName: "Business",
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "location",
      headerName: "Location",
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "plant",
      headerName: "Plant",
      minWidth: 190,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "area",
      headerName: "Area",
      minWidth: 230,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
      renderCell: (params) => params.value,
      filterOptions: {
        renderValue: (value) => `Area: ${value}`,
      },
    },

    {
      field: "addedBy",
      headerName: "Added By",
      minWidth: 220,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "addedOn",
      headerName: "Added On",
      minWidth: 240,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "action",

      headerName: "Action",

      minWidth: 240,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        iconsVisibleArea && (
        <>
          <div style={{ alignItems: "center"}}>
            <span style={{ marginRight: "10px" }}>
              <EditIcon onClick={() => handleEditClick(params.row)} style={{cursor:"pointer"}} />
            </span>
            <DeleteIcon
              variant="contained"
              color="error"
              onClick={() => {
                setSelectedItem(params.row.id);

                setConfirmationModalOpen(true);
              }}
              style={{cursor:"pointer"}}
            />
          </div>
        </>
        )
      ),
    },
  ];
  const OriginalplantColumns = [
    {
      field: "id",
      headerName: "S.No",
      minWidth: 170,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "business",
      headerName: "Business",
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "location",
      headerName: "Location",
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "plant",
      headerName: "Plant",
      minWidth: 190,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 190,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },
    {
      field: "addedBy",
      headerName: "Added By",
      minWidth: 220,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "action",

      headerName: "Action",

      minWidth: 240,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        iconsVisibleArea && (
        <>
          <div style={{ alignItems: "center"}}>
            <span style={{ marginRight: "10px" }}>
              <EditIcon onClick={() => handleEditClick(params.row)} style={{cursor:"pointer"}} />
            </span>
            <DeleteIcon
              variant="contained"
              color="error"
              onClick={() => {
                setSelectedItem(params.row.id);

                setConfirmationModalOpen(true);
              }}
              style={{cursor:"pointer"}}
            />
          </div>
        </>
        )
      ),
    },
  ];
  const columnsWithHiddenHeadersInner = OriginalareaColumns.map((column) => {
    if (column.field === 'action') {
      return {
        ...column,
        headerName: '',
        // Empty string to hide the header name
      };
    }
    return column;
  });
  const areaColumns=iconsVisibleArea ? OriginalareaColumns : columnsWithHiddenHeadersInner;


  const columnsWithHiddenHeadersplant = OriginalplantColumns.map((column) => {
    if (column.field === 'action') {
      return {
        ...column,
        headerName: '',
        // Empty string to hide the header name
      };
    }
    return column;
  });
  const plantColumns=iconsVisibleArea ? OriginalplantColumns : columnsWithHiddenHeadersplant;

  useEffect(() => {
    getareaDetails();
    getplantDetails();
  }, []);
  const getareaDetails = () => {
    let url = ApiURL + "/show_area";
    axios
      .get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
      
        }
      }
)
      .then((response) => {
        setAddedAreas(response.data);
      })
      .catch((error) => {
        setAddedAreas([]);
      });
  };
  const getplantDetails = () => {
    let url = ApiURL + "/show_plant_details";
    axios
      .get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
)
      .then((response) => {
        setAddedPlants(response.data);
      })
      .catch((error) => {
        setAddedPlants([]);
      });
  };
  useEffect(() => {
    getuserDetails();
  }, []);
  
  const getuserDetails = () => {
    let url = ApiURL + "/show_user";
    axios
      .get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
      
        }
      }
)
      .then((response) => {
        setAddedUsers(response.data);
      })
      .catch((error) => {
        setAddedUsers([]);
      });
  };

  const handleEdit = (rowData) => {
    if (activeTab === "area") {
    
      setSelectedCardData(rowData);
      setAddAreaModalOpen(true);
        const areaId = rowData.id;
        let url = ApiURL + `/get_current_area/${areaId}`;
        axios
          .get(url,{
            headers: {
              Authorization: `Bearer ${access_token}`,
             
            }
          }
    )
          .then((response) => {
            setCurrentAddedAreas(response.data);
           
          })
          .catch((error) => {
            setCurrentAddedAreas([]);
          });
    }
    else if(activeTab==="plant"){
      setSelectedPlantCardData(rowData);
      setAddPlantModalOpen(true);
        const plantId = rowData.id;
        let url = ApiURL + `/get_current_plant/${plantId}`;
        axios
          .get(url,{
            headers: {
              Authorization: `Bearer ${access_token}`,
             
            }
          }
    )
          .then((response) => {
            setCurrentAddedPlants(response.data);
          })
          .catch((error) => {
            setCurrentAddedPlants([]);
          });
    } else {
      setSelectedUserCardData(rowData);
      setAddUserModalOpen(true);

        const userId = rowData.id;
        let url = ApiURL + `/get_current_users/${userId}`;
        axios
          .get(url,{
            headers: {
              Authorization: `Bearer ${access_token}`,
             
            }
          }
    )
          .then((response) => {
            setCurrentAddedUsers(response.data);
          })
          .catch((error) => {
            setCurrentAddedUsers([]);
          });
      
    }
  };
  const handleEditClick = (rowData) => {
 
    if (activeTab === "area") {
      setSelectedRowData(rowData);
    
      setAddAreaModalOpen(true);
      if (rowData && rowData.id) {
        const filteredAreaRowsedit = areaFilter
? addedAreas.filter((area) =>
    area && area.plantName && area.plantName.toLowerCase().includes(areaFilter.toLowerCase())
  )
: addedAreas;
        const element = filteredAreaRowsedit[rowData.id - 1];
        const areaId = element.id;
        let url = ApiURL + `/get_current_area/${areaId}`;
        axios
          .get(url,{
            headers: {
              Authorization: `Bearer ${access_token}`,
             
            }
          }
    )
          .then((response) => {
            setCurrentAddedAreas(response.data);
          
          })
          .catch((error) => {
            setCurrentAddedAreas([]);
          });
      }
    }
    else if (activeTab==="plant"){
      setSelectedRowPlantData(rowData);
    
      setAddPlantModalOpen(true);
      if (rowData && rowData.id) {
        const element = addedPlants[rowData.id - 1];
        const areaId = element.id;
        let url = ApiURL + `/get_current_plant/${areaId}`;
        axios
          .get(url,{
            headers: {
              Authorization: `Bearer ${access_token}`,
             
            }
          }
    )
          .then((response) => {
            setCurrentAddedPlants(response.data);
          
          })
          .catch((error) => {
            setCurrentAddedPlants([]);
          });
      }
    }
    else {
      setSelectedRowUserData(rowData);
      setAddUserModalOpen(true);
     
      if (rowData && rowData.id) {
        const element = addedUsers[rowData.id - 1];
        const userId = element.id;
        let url = ApiURL + `/get_current_users/${userId}`;
        axios
          .get(url,{
            headers: {
              Authorization: `Bearer ${access_token}`,
             
            }
          }
    )
          .then((response) => {
            setCurrentAddedUsers(response.data);
           
          })
          .catch((error) => {
            setCurrentAddedUsers([]);
          });
      }
    }
  };

  const userColumns = [
    {
      field: "id",
      headerName: "S.No",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "business",
      headerName: "Business",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "plant",
      headerName: "Plant",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "name",
      headerName: "Name",
      minWidth: 190,
      align: "center",
      headerAlign: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "sgid",
      headerName: "SGID",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "role",
      headerName: "Role",
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "addedBy",
      headerName: "Added By",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },

    {
      field: "addedOn",
      headerName: "Added On",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      cellClassName: 'custom-column-style',
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <div style={{ alignItems: "center" }}>
            {(userDet.role_id === 1 && params.row.role === 'Super Admin') ? (
              <>
                <span style={{ marginRight: "10px" }}>
                  <EditIcon style={{ cursor: "not-allowed", opacity: 0.5 }} />
                </span>
                <span style={{ marginRight: "10px" }}>
                  <DeleteIcon style={{ cursor: "not-allowed", opacity: 0.5 }} />
                </span>
              </>
            ) : (
              <>
                <span style={{ marginRight: "10px" }}>
                  <EditIcon onClick={() => handleEditClick(params.row)} style={{ cursor: "pointer" }} />
                </span>
                <span style={{ marginRight: "10px" }}>
                  <DeleteIcon
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setSelectedItem(params.row.id);
                      setConfirmationModalOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </>
            )}
          </div>
        </>
      ),
    }
    
    


  ];

  const paginatedRows =  activeTab === 'area' ? areaRows :activeTab === 'plant' ?plantRows: userRows;
 

  const columns = activeTab === "area" ? areaColumns :activeTab === 'plant' ?plantColumns: userColumns;


  const handleSelectedYearChange = (event) => {
    const newYear = event.target.value;
    // Call the function from settingsResolver module with selectedYear as a parameter
    if (activeTab === "user") {
      getuserDateList(newYear);
      setSelectedUserYear(newYear); // Replace functionName with the actual function you want to call
    } else if (activeTab === "area") {
      setSelectedAreaYear(newYear);
      getAreaDateList(newYear);
    }
  };

  const getuserDateList = (yearz) => {
    const currentYear = yearz;
    let url = ApiURL + `/show_user/${currentYear}`;
    axios
      .get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
)
      .then((response) => {
        setAddedUsers(response.data);
      })
      .catch((error) => {
        setAddedUsers([]);
      });
  };

  const getAreaDateList = (yearz) => {
    
    const currentYear = yearz;
    let url = ApiURL + `/show_area/${currentYear}`;
    axios
      .get(url,{
        headers: {
          Authorization: `Bearer ${access_token}`,
         
        }
      }
)
      .then((response) => {
        setAddedAreas(response.data);
      })
      .catch((error) => {
        setAddedAreas([]);
      });
  };

  return (
    <div>
    <div className="desktop-view">
     
      <Grid container style={{padding: '10px 10px 10px '}} justifyContent={"space-between"} alignItems={"center"} >
        <Grid item>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="User Details" value="user" style={tabStyle("user")} />

            <Tab label="Area Details" value="area" style={tabStyle("area")} />
            <Tab label="Plant Details" value="plant" style={tabStyle("plant")} />
          </Tabs>
        </Grid>

        <Grid item xl="4" lg="6" >
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            marginRight={1}
          >
            {activeTab === "area" && (
              <Grid item md="3" >
                <FormControl fullWidth size="small">
                  <InputLabel>Filter Plant</InputLabel>
                  <Select
                    className="select-nis"
                    value={areaFilter}
                    onChange={handleAreaFilterChange}
                  >
                    <MenuItem value="">All </MenuItem>
                    {uniqueAreas.map((areaValue, index) => (
                      <MenuItem key={index} value={areaValue}>
                       
                       {areaValue ? areaValue.toUpperCase() : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {activeTab !== "plant"&&(
            <Grid item md="6" >
              <YearSelectBox
                selectedYear={
                  activeTab === "user" ? selectedUserYear : selectedAreaYear
                }
                years={years}
                onSelectedYearChange={handleSelectedYearChange}
              />
            </Grid>
             )}
            {activeTab === "area" && userDet.role_id === 3 && (
  <Grid item>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      style={{ backgroundColor: '#002060' }}
      onClick={handleAddAreaClick}
    >
      New Area
    </Button>
  </Grid>
)}
{activeTab === "user" && (
  <Grid item>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      style={{ backgroundColor: '#002060' }}
      onClick={handleAddUserClick}
    >
      New User
    </Button>
  </Grid>
)}
{activeTab === "plant" &&  userDet.role_id === 3 &&(
  <Grid item>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      style={{ backgroundColor: '#002060' }}
      onClick={handleAddPlantClick}
    >
      New Plant
    </Button>
  </Grid>
)}

          </Grid>
        </Grid>
      </Grid>

      <Dialog open={confirmationModalOpen} onClose={handleCancelDelete}>
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>

          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={paginatedRows}
          columns={columns}
          pageSize={rowsPerPage}
          checkboxSelection
          
        />
      </div>

      <AddArea
        open={addAreaModalOpen}
        onClose={handleAddAreaModalClose}
        onSave={handleSaveArea}
        selectedRowData={selectedRowData}
        selectedCardData={selectedCardData}
        addedAreas={currentaddedAreas}
        userDet={userDet}
        onHandleArea={getareaDetails}
        onShowAlertMessage={handleOpenAlert}
      />
      <AddUser
        open={addUserModalOpen}
        onHandleUser={getuserDetails}
        onClose={handleAddUserModalClose}
       
        onSave={handleSaveUser}
        onHandleUserDateList={getuserDateList}
        selectedRowData={selectedRowUserData}
        selectedUserCardData={selectedUserCardData}
        userDet={userDet}
        addedUsers={currentaddedUsers}
        onShowAlert={handleOpenAlert}
        selectedUserYear={selectedUserYear}
      setIsYearSelected={setIsYearSelected}
        
      />
 <AddPlant
        open={addPlantModalOpen}
        onClose={handleAddPlantModalClose}
        onSave={handleSavePlant}
        selectedRowData={selectedRowPlantData}
        selectedPlantCardData={selectedPlantCardData}
        addedAreas={currentaddedPlants}
        userDet={userDet}
        onHandleArea={getplantDetails}
        onShowAlertMessage={handleOpenAlert}
      />
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseAlert}
          severity="success"
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
     <div className="mobile-view">
   
     <Grid container justifyContent={'space-between'} style={{ display: 'flex' }} alignItems={'center'}>
          <Grid item >
            <Typography sx={{ fontWeight: 400, fontSize: 16 }}>
              Settings
            </Typography>
          </Grid>
          <Grid item >
         <Grid
           container
           justifyContent={"space-between"}
           alignItems={"center"}
           marginRight={1}
         >
                     {activeTab === "area" && userDet.role_id === 3 && (
  <Grid item>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      style={{ backgroundColor: '#002060' }}
      onClick={handleAddAreaClick}
    >
     
    </Button>
  </Grid>
)}
{activeTab === "user" && (
  <Grid item>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      style={{ backgroundColor: '#002060' }}
      onClick={handleAddUserClick}
    >
    
    </Button>
  </Grid>
)}
{activeTab === "plant" &&  userDet.role_id === 3 &&(
  <Grid item>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      style={{ backgroundColor: '#002060' }}
      onClick={handleAddPlantClick}
    >
     
    </Button>
  </Grid>
)}
         </Grid>
       </Grid>
          <hr style={{ margin: '1px 0', color: '#fafafa' }} />
          <Grid item>
     <Grid container style={{padding: '10px 10px 10px '}} justifyContent={"space-between"} alignItems={"center"} >
       <Grid item>
         <Tabs value={activeTab} onChange={handleTabChange} centered>
           <Tab label="User Details" value="user" style={tabStyleMobile("user")} />

           <Tab label="Area Details" value="area" style={tabStyleMobile("area")} />
           
           <Tab label="Plant Details" value="plant" style={tabStyleMobile("plant")} />
         </Tabs>
       </Grid>

     
     </Grid>
     </Grid>
     </Grid>

     <Dialog open={confirmationModalOpen} onClose={handleCancelDelete}>
       <DialogTitle>Are you sure you want to delete?</DialogTitle>
       <DialogActions>
         <Button onClick={handleCancelDelete}>Cancel</Button>

         <Button
           onClick={handleConfirmDelete}
           variant="contained"
           color="primary"
         >
           Confirm
         </Button>
       </DialogActions>
     </Dialog>
     <div style={{ width: "100%" }}>
     {activeTab === "area" ? (
             <Grid item xs='12'>
         {addedAreas.map((data, index) => (
               <Card key={data.id} style={cardStyle}>
                 <CardContent style={contentStyle}>
                   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                     <div style={{ marginBottom: '10px' }}>
                       {data.area}
                     </div>
                     <div style={topRightCornerStyle}>
                     {userDet.role_id === 3 ? (
  <IconButton aria-label="more" onClick={(event) => handleOpenMenu(event, data, index)}>
    <MoreVertIcon style={{ color: '#000000' }} />
  </IconButton>
) : null}
                       <Menu anchorEl={menuAnchor[index]} open={Boolean(menuAnchor[index])} onClose={() => handleCloseMenu(index)}>
                         <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>
                         <MenuItem onClick={() =>{
                            setSelectedDeleteItem(data)
                            setConfirmationModalOpen(true)}}>Delete</MenuItem>
                       </Menu>
                     </div>
                   </div>
   
                   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                     <Typography variant="subtitle2" color="textSecondary">
                       Created by: {data.name}
                     </Typography>
                     <Typography variant="subtitle2" color="textSecondary">
                       {data.created_on?.split('T')[0] || ''}
                     </Typography>
                   </div>
                 </CardContent>
               </Card>
             ))}

           </Grid>
           ) :activeTab === "plant" ? (
            <Grid item xs='12'>
        {addedPlants.map((data, index) => (
              <Card key={data.id} style={cardStyle}>
                <CardContent style={contentStyle}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ marginBottom: '10px' }}>
                      {data.plantName}
                    </div>
                    <div style={topRightCornerStyle}>
                    {userDet.role_id === 3 ? (
  <IconButton aria-label="more" onClick={(event) => handleOpenMenu(event, data, index)}>
    <MoreVertIcon style={{ color: '#000000' }} />
  </IconButton>
) : null}
                      <Menu anchorEl={menuAnchor[index]} open={Boolean(menuAnchor[index])} onClose={() => handleCloseMenu(index)}>
                        <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>
                        <MenuItem onClick={() =>{
                           setSelectedDeleteItem(data)
                           setConfirmationModalOpen(true)}}>Delete</MenuItem>
                      </Menu>
                    </div>
                  </div>
  
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Created by: {data.name}
                    </Typography>
                 
                  </div>
                </CardContent>
              </Card>
            ))}

          </Grid>
          ): (
            <Grid item xs='12'>
            {addedUsers.map((data, index) => (
              <Card key={data.id} style={cardStyle}>
                <CardContent style={contentStyle}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ marginBottom: '10px' }}>
                      {data.name}
                    </div>
                    <div style={topRightCornerStyle}>
               
                    {userDet.role_id === 3 || ['admin', 'User'].includes(data.role_name) ? (
  <IconButton aria-label="more" onClick={(event) => handleOpenMenu(event, data, index)}>
    <MoreVertIcon style={{ color: '#000000' }} />
  </IconButton>
) : null}
                       <Menu anchorEl={menuAnchor[index]} open={Boolean(menuAnchor[index])} onClose={() => handleCloseMenu(index)}>
                         <MenuItem onClick={() => handleEdit(data)}>Edit</MenuItem>
                         <MenuItem onClick={() =>{
                          setSelectedDeleteItem(data)
                            setConfirmationModalOpen(true)}}>Delete</MenuItem>
                       </Menu>
                     </div>
                   </div>
                   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Role: {data.role_name}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Created by: {data.created_by}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {data.created_on?.split('T')[0] || ''}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            ))}
          </Grid>
           )}
     </div>

     <AddArea
       open={addAreaModalOpen}
       onClose={handleAddAreaModalClose}
       onSave={handleSaveArea}
       selectedCardData={selectedCardData}
       selectedRowData={selectedRowData}
       addedAreas={currentaddedAreas}
       userDet={userDet}
       onHandleArea={getareaDetails}
       onShowAlertMessage={handleOpenAlert}
     />

     <AddUser
       open={addUserModalOpen}
       onHandleUser={getuserDetails}
       onClose={handleAddUserModalClose}
       onSave={handleSaveUser}
       selectedUserCardData={selectedUserCardData}
       selectedRowUserData={selectedRowUserData}
       userDet={userDet}
       addedUsers={currentaddedUsers}
       onShowAlert={handleOpenAlert}
       onHandleUserDateList={getuserDateList}
       selectedUserYear={selectedUserYear}
       setIsYearSelected={setIsYearSelected}
     />
      <AddPlant
        open={addPlantModalOpen}
        onClose={handleAddPlantModalClose}
        onSave={handleSavePlant}
        selectedRowPlantData={selectedRowPlantData}
        selectedPlantCardData={selectedPlantCardData}
        addedAreas={currentaddedPlants}
        userDet={userDet}
        onHandleArea={getplantDetails}
        onShowAlertMessage={handleOpenAlert}
      />

     <Snackbar
       open={alertOpen}
       autoHideDuration={3000}
       onClose={handleCloseAlert}
     >
       <MuiAlert
         elevation={6}
         variant="filled"
         onClose={handleCloseAlert}
         severity="success"
       >
         {alertMessage}
       </MuiAlert>
     </Snackbar>
   </div>
   </div>
  );
}

export default Settings;
