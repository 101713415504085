import React, { useState ,useEffect} from 'react';

import { useRecoilState } from 'recoil';
import {  InputLabel, Grid,Container } from '@mui/material';
import { viewedReportDataState} from '../../../../recoil/atoms';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const Objective = ({ formData, setFormData}) => {
 
  const { title,n_gie,objective,main_conclusion,next_step} = formData;
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
  const [initialTitleData, setInitialTitleData] = useState('');
  const [initialNgieData, setInitialNgieData] = useState('');
  const [initialObjectiveData, setInitialObjectiveData] = useState('');
  const [initialMainConclusionData, setInitialMainConclusionData] = useState('');
  const [initialNextStepData, setInitialNextStepData] = useState('');
  

  
  useEffect(() => {
    if (viewedReportData.title !== undefined && viewedReportData.title !== initialTitleData) {
      setInitialTitleData(viewedReportData.title);
      setFormData({ ...formData, title: viewedReportData.title });
    }
  }, [viewedReportData.title,initialTitleData, formData, setFormData]);
  useEffect(() => {
    if (viewedReportData.n_gie !== undefined && viewedReportData.n_gie !== initialNgieData) {
      setInitialNgieData(viewedReportData.n_gie);
      setFormData({ ...formData, n_gie: viewedReportData.n_gie });
    }
  }, [viewedReportData.n_gie,initialNgieData, formData, setFormData]);
  useEffect(() => {
    if (viewedReportData.objective !== undefined && viewedReportData.objective !== initialObjectiveData) {
      setInitialObjectiveData(viewedReportData.objective);
      setFormData({ ...formData, objective: viewedReportData.objective });
    }
  }, [viewedReportData.objective,initialObjectiveData, formData, setFormData]);
  useEffect(() => {
    if (viewedReportData.main_conclusion !== undefined && viewedReportData.main_conclusion !== initialMainConclusionData) {
      setInitialMainConclusionData(viewedReportData.main_conclusion);
      setFormData({ ...formData, main_conclusion: viewedReportData.main_conclusion });
    }
  }, [viewedReportData.main_conclusion,initialMainConclusionData, formData, setFormData]);
  useEffect(() => {
    if (viewedReportData.next_step !== undefined && viewedReportData.next_step !== initialNextStepData) {
      setInitialNextStepData(viewedReportData.next_step);
      setFormData({ ...formData, next_step: viewedReportData.next_step });
    }
  }, [viewedReportData.next_step,initialNextStepData, formData, setFormData]);
  
  const handleTitleEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, ''); 
    const updatedViewedReportData = { ...viewedReportData, title:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, title:content });
    
  };
  
  const handleNgieEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, n_gie:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, n_gie:content });
    
  };
  const handleObjectiveEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, objective:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, objective:content });
    
  };
  const handleMainConclusionEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, main_conclusion:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, main_conclusion:content });
    
  };
  const handleNextStepEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, next_step:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, next_step:content });
    
  };
  
  const editorConfig = {
   
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
      // Add other configuration options as needed
      
  };
  return (
    <div>
      <Container maxWidth="lg" style={{ padding: '0 16px' }}>
      <form >
        <Grid container spacing={2}>
          <Grid item xs={12} >
          <InputLabel style={{ color: 'black', textAlign: 'left',marginTop:"16px" }}>Title of the Memo</InputLabel>
          <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.title !== undefined ? viewedReportData.title : title}
                onChange={(event, editor) => {
                  handleTitleEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
          </Grid>
          <Grid item xs={12} >
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>N GIE</InputLabel>
          <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.n_gie !== undefined ? viewedReportData.n_gie : n_gie}
                onChange={(event, editor) => {
                  handleNgieEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
          </Grid>
          <Grid item xs={12} >
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>Objective</InputLabel>
          <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.objective !== undefined ? viewedReportData.objective : objective}
                onChange={(event, editor) => {
                  handleObjectiveEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
          </Grid>
          <Grid item xs={12} >
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>Main Conclusion</InputLabel>
          <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.main_conclusion !== undefined ? viewedReportData.main_conclusion : main_conclusion}
                onChange={(event, editor) => {
                  handleMainConclusionEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
          </Grid>
          
          <Grid item xs={12}>
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>Next Step</InputLabel>
          <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.next_step !== undefined ? viewedReportData.next_step : next_step}
                onChange={(event, editor) => {
                  handleNextStepEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
          </Grid>
          
         
        </Grid>

        

        
      </form>
  
      </Container>
    </div>
  );
};
export default Objective;



