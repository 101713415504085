
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import axios from 'axios';
import configParam from '../../config';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from "recoil";
import { LocationId,userLocationState} from '../../recoil/atoms';
import { Link as RouterLink } from 'react-router-dom';
import { userPlantState } from '../../recoil/atoms';
import { useLocation } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
const ApiURL = configParam.API_URL;


const access_token=localStorage.getItem("accesstoken");
function LocationCards() {
  const [, setLocationId] = useRecoilState(LocationId);
  const [userLocation,setUserLocation]=useRecoilState(userLocationState)
  const { businessId } = useParams();
  const [location, setLocation] = useState([]);
  const [plantData, setPlantData] = useState({});
  const [plantDet, setPlantDet] = useState('');
  const [breadcrumb, setBreadcrumb] = useState('');
  const userPlant = useRecoilValue(userPlantState);
  const [, setLocationDet] = useState({});
  const [userDet, setUserDet] = useState({});
  const loc = useLocation();

  useEffect(() => {
    let data = localStorage.getItem('user');
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);
  useEffect(() => {
    // Extract the last part of the current path as the breadcrumb
    const pathnames = loc.pathname.split('/').filter((path) => path);
    if (pathnames.length > 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 2];
      setBreadcrumb(currentBreadcrumb);
    }
    if (pathnames.length === 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 1];
      setBreadcrumb(currentBreadcrumb);
    }
  }, [loc]);


  useEffect(() => {
    setLocationDet(userLocation || {});
  }, [userLocation]);

  useEffect(() => {
 setPlantDet(userPlant)
  }, [userPlant]); // Store plant data (count and averageNi)

  const navigate = useNavigate();
  
  useEffect(() => {
    const P_Data = async (plantName) => {
      try {
        const plant_Name = plantName;
        let data1 = localStorage.getItem('user');
        let User = JSON.parse(data1);
      
        const response = await axios.get(ApiURL + `/location_cardsss/${plant_Name}/${User.id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        const data = response.data;
        return data;
      } catch (error) {
        console.error('Error fetching plant data:', error);
        return { count: 0, averageNi: 0 }; // Handle error gracefully
      }
    };

    const fetchPlants = async () => {
      try {
        const response = await axios.get(ApiURL + `/location_cards/${businessId}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        const data = response.data;
        setLocation(data);

        const plantDataPromises = data.map((plant) => P_Data(plant.id));
        const plantDataArray = await Promise.all(plantDataPromises);

        const plantDataObject = data.reduce((acc, plant, index) => {
          acc[plant.id] = plantDataArray[index];
          return acc;
        }, {});

        setPlantData(plantDataObject);
      } catch (error) {
        console.error('Error fetching plants:', error);
      }
    };

    fetchPlants();
  }, [businessId]);
  
  
  const handlePlantCardClick = (id) => {
    const url = `/plants/${id}`;
    setLocationId(id);
    navigate(url);
  };
  useEffect(() => {
    if (location.length > 0) { // Check if the array is not empty
      localStorage.setItem('location_id', JSON.stringify(location));
      setUserLocation(location)
    }
  }, [location]);
  
  

function formatBusinessName(name) {
  if (name) {
    return name.toUpperCase();
  }
  return ''; // Handle the case where name is undefined or null
}
  const isMobileView = useMediaQuery('(max-width:600px)');
  return (
    <div>
     {isMobileView && (
      <div>
      
        
        {userDet.role_id === 2? (
          <div style={{ display: 'flex' }}>
            <RouterLink to={`/plants/${userDet.location_id}`}>
              <Typography style={{ color: '#1C1C1C' }}>Home</Typography>
            </RouterLink>
            <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <RouterLink to="/business">
              <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>Home</Typography>
            </RouterLink>
            <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
            {userDet && (
              <RouterLink to={`/location/${userDet.business_id}`}>
                <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(userDet.location)}</Typography>
              </RouterLink>
            )}
            {userDet && (
              <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
            )}
            {userDet && plantDet && (
              <RouterLink to={`/plants/${userDet.location_id}`}>
                <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>{formatBusinessName(plantDet)}</Typography>
              </RouterLink>
            )}
            {userDet && (
              <Typography style={{ color: '#1C1C1C', marginRight: '5px' }}>/</Typography>
            )}
            <Typography style={{ color: '#1C1C1C', fontSize: '16px', fontWeight: 600 }}>{formatBusinessName(breadcrumb)}</Typography>
          </div>
          
        )
        }
        
      </div>
    )}
        {isMobileView ? (
  <hr style={{ margin: '10px 0', borderColor: '#0000001A' }} />
) : null}
     <Grid
      container
      className="plant-container"
      spacing={2}
      style={{ marginTop: '20px' }}
    >
      {location.map((plant) => (
        <Grid item key={plant.location} xs={12} sm={6} md={4} lg={3}>
          <Link to={`/Plants/${plant.id}`}>
            <Card
              sx={{
                backgroundColor: '#002060',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                color: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
       
              }}
              onClick={() => {
                handlePlantCardClick(plant.id);
              }}
            >
              <CardContent>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div>
                    <Typography style={{ fontSize: "16px", fontWeight: 500, color: '#FFFFFF' }}>
                    {formatBusinessName(plant.location)}
                    </Typography>
                    <Typography  style={{ fontWeight:600,fontSize:"36px",color: '#FFFFFF' }}>
                      {plantData[plant.id] ? plantData[plant.id].count : 'Loading...'}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      marginTop:"30px"
                    }}
                  >
                    <Typography style={{ fontWeight: 400, fontSize: "14px", color: '#FFFFFF' }}>
                      Total Nickel %:
                    </Typography>
                    <Typography  style={{  fontWeight:400,fontSize:"14px",color: '#FFFFFF' }}>
                      {plantData[plant.id] ? plantData[plant.id].averageNi : 'Loading...'}
                    </Typography>

                  </div>

                </div>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        
      ))}
    </Grid>
    </div>
  );
}




export default LocationCards;