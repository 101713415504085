import decode from 'jwt-decode';
const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev-nis2.0-api.saint-gobain.com";
    case "prod":
      return "https://sgnifi-api.saint-gobain.com";
    default:
      return "http://localhost:3044";
  }
};

const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {

    case 'dev':
      return 'https://dev.indec4.saint-gobain.com/aim';
    case 'prod':
      return 'https://dc02.saint-gobain.com/aim';
    default:
      return 'http://localhost:3010';
  }
};
const _get_sso_url = function () {

  switch (process.env.REACT_APP_STAGE) {

    case "dev":

      return 'https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_NiS_2.0&redirect_uri=https://dev-nis2.0-api.saint-gobain.com/login&service=simple&scope=stGoSGI';

    case "prod":

      return 'https://cloudsso.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=NiS&redirect_uri=https://sgnifi-api.saint-gobain.com/login&service=simple&scope=stGoSGI';

    default:

      return 'https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_NiS_2.0&redirect_uri=http://localhost:3044/login&service=simple&scope=stGoSGI';

  }

}

const _get_auth_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'https://4sight.indec4.saint-gobain.com/auth/';
    case 'prod':
      return 'https://dc03.saint-gobain.com/auth/';
    default:
      return 'https://4sight.indec4.saint-gobain.com/auth/';
  }
};
const _get_graphql_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'https://4sight.indec4.saint-gobain.com/v1/graphql';
    case 'prod':
      return 'https://dc02.saint-gobain.com/aimapi/v1/graphql';
    default:
      return 'https://4sight.indec4.saint-gobain.com/v1/graphql';
  }
};
const checkAndGetToken = (token) => {
  let decodedToken = decode(token, { complete: true });
  let dateNow = new Date();
  if (
    decodedToken.exp < parseInt(dateNow.getTime() / 1000) ||
    decodedToken.exp === parseInt(dateNow.getTime() / 1000)
  ) {
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    let url = configParam.AUTH_URL + 'refresh';
    fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ refresh_token: token })
    })
      .then((response) => response.json())
      .then(async (result) => {
        if (Object.keys(result).length > 0) {
          localStorage.setItem('MyRegenAccessToken', result['access_token']);
          return result['access_token'];
        } else {
          
        }
      })
      .catch((error) => console.log('error', error));
  } else {
    return token;
  }
};
const _run_gql_api = async (query, variables, retries = 3) => {
  let accessToken = localStorage.getItem('MyRegenAccessToken');
  if (!accessToken) {
    window.location.pathname = '/myRegen/login';
  }

  let controller = new AbortController();
  let signal = controller.signal;

  let token = checkAndGetToken(accessToken.replace(/['"]+/g, ''));

  if (!token) {
    localStorage.setItem('NisAccessToken', '');
    localStorage.setItem('NisRefreshToken', '');
    localStorage.setItem('userDetails', '');
    window.location.pathname = '/myRegen/login';
  }
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', 'Bearer ' + token);
  setTimeout(() => controller.abort(), 20000);
  return fetch(_get_graphql_url(), {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ query: query, variables: variables }),
    signal: signal
  })
    .then((response) => response.json())
    .then((result) => {
      
      return result['data'];
    })
    .catch((e) => {
      if (retries > 0) return _run_gql_api(query, variables, retries - 1);
      else {

        return e;
      }
    });
};
const _run_rest_api = async (url, data, post, retries = 3) => {
  let controller = new AbortController();
  let signal = controller.signal;
  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  setTimeout(() => controller.abort(), 60000);
  let reqOptions = {
    method: post ? 'POST' : 'GET',
    signal: signal
  };
  let urlLink = _get_auth_url() + url;
  let urlApp = _get_app_url();
  if (url === 'login') {
    urlLink += `?app=${urlApp}&tenant_id=fac735fa-66ab-4433-aef0-928c230401fe`;
  }
  if (post) {
    reqOptions.body = JSON.stringify(data);
    reqOptions.redirect = 'follow';
  } else {
    let token = localStorage.getItem('NisAccessToken');
    myHeaders.append('Authorization', 'Bearer ' + token.replace(/['"]+/g, ''));
  }
  reqOptions.headers = myHeaders;
  return fetch(urlLink, reqOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((e) => {
      if (retries > 0) return _run_rest_api(url, data, post, retries - 1);
      else {
        return e;
      }
    });
};

const setSgId = (id) => localStorage.setItem('UserID', id);
const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
const SGid = function () {
  const user = localStorage.getItem('UserID');
  return user;
};
const getData = () => localStorage.getItem('NisData');
const getVisitedData = () => localStorage.getItem('Visited_Item');
const _get_visit_no = function () {
  const myRegenFeatures = localStorage.getItem('NisData');
  const UserID = localStorage.getItem('UserID');
  let data = JSON.parse(myRegenFeatures);
  const keys = data ? data[UserID] : {};
  return Object.keys(keys).length;
};

const isNotNum = (num) => (isNaN(num) || num === Infinity || num === "" || num === null || num === undefined);
const isNum = (num) => !isNotNum(num);

const _round_up = (number, decimalPlaces = 2) => isNum(number) ? Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces) : "";

export const configParam = {
  primaryColor: '#FF6D04',
  lightBackground: '#FC8C200C',
  grey: '#D8D8D8',
  API_URL: _get_api_url(),
  DEEP_COPY: deepCopy,
  SG_ID: SGid,
  GET_DATA: getData(),
  VISIT_NO: _get_visit_no,
  RUN_REST_API: _run_rest_api,
  getVisitedItem: getVisitedData,
  AUTH_URL: _get_auth_url(),
  APP_URL: _get_app_url(),
  SET_SG_ID: setSgId,
  RUN_SSO_URL: _get_sso_url,
  RUN_GQL_API: _run_gql_api,
  isNaN: isNotNum,
  isNo: isNum,
  Round_Up: _round_up,
};

export default configParam;