import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography,Grid } from '@mui/material';
import axios from 'axios';
import configParam from '../../config';
import { Link } from 'react-router-dom';
import { useParams  } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from "recoil";
import { plantId } from '../../recoil/atoms';
import { userPlantState} from '../../recoil/atoms';
import { userLocationState } from '../../recoil/atoms';
import {  useLocation } from "react-router-dom";

const ApiURL = configParam.API_URL;

const access_token=localStorage.getItem("accesstoken");
function PlantCards() {
  const [PlantId, setPlantId] = useRecoilState(plantId);
  const { businessId } = useParams();
  const [,setPlantname]=useRecoilState(userPlantState);
  const [plants, setPlants] = useState([]);
  const [plantData, setPlantData] = useState({}); // Store plant data (count and averageNi)
  const [userDet, setUserDet] = useState({});
  const [, setPlantDet] = useState('');
  const [, setBreadcrumb] = useState('');
  const userPlant = useRecoilValue(userPlantState);
  const userLocation=useRecoilValue(userLocationState);
  const [, setLocationDet] = useState({});
 
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the last part of the current path as the breadcrumb
    const pathnames = location.pathname.split('/').filter((path) => path);
    if (pathnames.length > 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 2];
      setBreadcrumb(currentBreadcrumb);
    }
    if (pathnames.length === 1) {
      const currentBreadcrumb = pathnames[pathnames.length - 1];
      setBreadcrumb(currentBreadcrumb);
    }
  }, [location]);


  useEffect(() => {
    setLocationDet(userLocation || {});
  }, [userLocation]);
  useEffect(() => {
 setPlantDet(userPlant)
  }, [userPlant]);


  
  useEffect(() => {
    let data = localStorage.getItem('user');
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);

  useEffect(() => {
    const P_Data = async (plantName) => {
      try {
        const plant_Name = plantName;
        const response = await axios.get(ApiURL + `/plant_cards/${plant_Name}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        const data = response.data;
       
        return data;
      } catch (error) {
        console.error('Error fetching plant data:', error);
        return { count: 0, averageNi: 0 }; // Handle error gracefully
      }
    };

    const fetchPlants = async () => {
      try {
        const response = await axios.get(ApiURL + `/business_cards/${userDet.location_id}/${userDet.id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
           
          }
        });

        const data = response.data;
      
        setPlants(data);

        const plantDataPromises = data.map((plant) => P_Data(plant.id));
        const plantDataArray = await Promise.all(plantDataPromises);
        
        const plantDataObject = data.reduce((acc, plant, index) => {
          acc[plant.id] = plantDataArray[index];
          return acc;
        }, {});
        
        setPlantData(plantDataObject);
      } catch (error) {
        console.error('Error fetching plants:', error);
      }
    };

    fetchPlants();
  }, [businessId,userDet]);

  const handlePlantCardClick = (id,name) => {    
      const url = `/analytics/${id}`;
      setPlantId(id);   
      navigate(url);   
  };
  useEffect(() => {
    const fetchPlants = async () => {
      if (PlantId) {
        const newUser = {
          id:  userDet.id ,
          plant:  PlantId,
          }
         const latestplantid=await axios.post(ApiURL+'/update_latest_plant_user', newUser,{
          headers: {
            Authorization: `Bearer ${access_token}`,
            
          }
        }
    )
        localStorage.setItem('user_id', PlantId);
        const plant = plants.find(plant => plant.id === PlantId);
  
        // Check if a matching plant was found and retrieve its name
        if (plant) {
          const plantName = plant.plantName;
          localStorage.setItem('user_plant', plantName);
          setPlantname(plantName);
        }
      }
    }
    fetchPlants();
   
  }, [PlantId,plants]);


  const groupedPlants = plants.reduce((acc, plant) => {
    if (!acc[plant.name]) {
      acc[plant.name] = [];
    }
    acc[plant.name].push(plant);
    return acc;
  }, {});
  function formatBusinessName(name) {
    const trimmedName = name.trim(); // Remove extra spaces
    if (trimmedName.length <= 3) {
      return trimmedName.toUpperCase();
    } else {
      return trimmedName.toUpperCase();
    }
  }
  // Function to render a group of cards
  const renderPlantGroup = (plantName, plantGroup) => {
    return (
      <div key={plantName}>
        <Typography variant="h5" style={{ marginTop: '20px' }}>
          {plantName}
        </Typography>
        <Grid
          container
          className="plant-container"
          spacing={2}
          style={{ marginTop: '20px' }}
        >
          {plantGroup.map((plant) => (
           <Grid item key={plant.id} xs={12} sm={6} md={4} lg={3}>
           <Link to={`/analytics/${plant.id}`}>
           <Card
             sx={{
               backgroundColor: '#002060',
               boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
               cursor: 'pointer',
               color: '#FFFFFF',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'space-between',
             }}
             onClick={() => {
               handlePlantCardClick(plant.id);
             }}
           >
             <CardContent>
             <div style={{ justifyContent: 'space-between', display: 'flex' }}>
             <div>
             <Typography style={{ fontSize: "16px", fontWeight: 500, color: '#FFFFFF' }}>
             {formatBusinessName(plant.plantName)}
     </Typography>
           
               <Typography  style={{ fontWeight:600,fontSize:"36px",color: '#FFFFFF' }}>
                 {plantData[plant.id] ? plantData[plant.id].count : 'Loading...'}
               </Typography>
             </div>
               <div
                 style={{
                   display: 'flex',
                   flexDirection: 'column',
                   alignItems: 'flex-end',
                   marginTop:"30px",
                 }}
               >
                 <Typography style={{ fontWeight: 400, fontSize: "14px", color: '#FFFFFF' }}>
                   Total Nickel %:
                 </Typography>
                 <Typography style={{  fontWeight:400,fontSize:"14px",color: '#FFFFFF' }}>
                   {plantData[plant.id] ? plantData[plant.id].averageNi : 'Loading...'}
                 </Typography>
               </div>
               </div>
              </CardContent>
             
           </Card>
         </Link>
       </Grid>
          ))}
        </Grid>
      </div>
    );
  };
  const categoryOrder = ["Float lines", "Processing plants", "Other /External"];
  return (
    <div>
       {categoryOrder.map((categoryName) => {
      const plantGroup = groupedPlants[categoryName];
      if (plantGroup) {
        return renderPlantGroup(categoryName, plantGroup);
      }
      return null;
    })}
    {/* {Object.entries(groupedPlants).map(([plantName, plantGroup]) =>
      renderPlantGroup(plantName, plantGroup)
    )} */}
  </div>
  );
}

  


export default PlantCards;

