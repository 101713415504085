import React, { useEffect} from 'react';

import { useRecoilState } from 'recoil';
import {  TextField, RadioGroup, FormControlLabel, Radio, InputLabel, FormControl, Grid,Container } from '@mui/material';
import { viewedReportDataState} from '../../../../recoil/atoms';
const AreaDes = ({ formData, setFormData}) => {
 
  const { from, to, copy, date, reference, confidentiality } = formData;
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
 

  useEffect(() => {
    
    if (viewedReportData.date !== date) {
      
      setFormData({ ...formData, date: viewedReportData.date?viewedReportData.date.split("T")[0]:""});
    }
    
    if (viewedReportData.reference !== reference) {
      setFormData({ ...formData, reference: viewedReportData.reference });
    }
    if (viewedReportData.confidentiality !== confidentiality) {
      setFormData({ ...formData, confidentiality: viewedReportData.confidentiality });
    }
    if (viewedReportData.from !== from) {
      setFormData({ ...formData, from: viewedReportData.from });
    }
    if (viewedReportData.to !== to) {
      setFormData({ ...formData, to: viewedReportData.to });
    }
    if (viewedReportData.copy !== copy) {
      setFormData({ ...formData, copy: viewedReportData.copy });
    }
  }, [viewedReportData.from,viewedReportData.to,viewedReportData.copy,viewedReportData.date,viewedReportData.reference,viewedReportData.confidentiality, from,to,copy,date,reference,confidentiality]);


  const handleFromChange = (event) => {
    const updatedViewedReportData = { ...viewedReportData, from: event.target.value };
    
    setViewedReportData(updatedViewedReportData)
    setFormData({ ...formData, from: event.target.value });
  };
  const handleToChange = (event) => {
    const updatedViewedReportData = { ...viewedReportData, to: event.target.value };
   
    setViewedReportData(updatedViewedReportData)
    setFormData({ ...formData, to: event.target.value });
  };
  const handleCopyChange = (event) => {
    const updatedViewedReportData = { ...viewedReportData, copy: event.target.value };
    
    setViewedReportData(updatedViewedReportData)
    setFormData({ ...formData, copy: event.target.value });
  };
  const handleDateChange = (event) => {
    const updatedViewedReportData = { ...viewedReportData, date: event.target.value };
    
    setViewedReportData(updatedViewedReportData)
    setFormData({ ...formData, date: event.target.value });
  };
  
  const handleReferenceChange = (event) => {
    const updatedViewedReportData = { ...viewedReportData, reference: event.target.value };
    
    setViewedReportData(updatedViewedReportData)
    setFormData({ ...formData, reference: event.target.value });
  };
  const handleConfidentialityChange = (event) => {
    const updatedViewedReportData = { ...viewedReportData, confidentiality: event.target.value };
  
    setViewedReportData(updatedViewedReportData)
    setFormData({ ...formData, confidentiality: event.target.value });
  };
  return (
    <div>
      <Container maxWidth="lg" style={{ padding: '0 16px' }}>
      <form >
        <Grid container spacing={2}>
          <Grid item xs={12} >
          <InputLabel style={{ color: 'black', textAlign: 'left',marginTop:"16px" }}>From</InputLabel>
            <FormControl fullWidth>
              
              <TextField id="from" type="text"size="small" className="input-nis"fullWidth value={from} onChange={handleFromChange} />
            </FormControl>
          </Grid>
          <Grid item xs={12} >
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>To</InputLabel>
            <FormControl fullWidth>
              
              <TextField id="to" type="text" size="small" className="input-nis"value={to} onChange={handleToChange}/>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>Copy</InputLabel>
            <FormControl fullWidth>
              
              <TextField id="copy" type="text" size="small" className="input-nis"value={copy} onChange={handleCopyChange}/>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>Date</InputLabel>
            <FormControl fullWidth>
             
              <TextField id="date" type="date" size="small" className="input-nis" value={date} onChange={handleDateChange}/>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6}>
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>Reference</InputLabel>
            <FormControl fullWidth>
              
              <TextField id="reference" type="text" size="small" className="input-nis"value={reference} onChange={handleReferenceChange}/>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          <InputLabel style={{ color: 'black', textAlign: 'left' }}>Confidentiality</InputLabel>
            <FormControl fullWidth>

            <RadioGroup value={confidentiality} onChange={handleConfidentialityChange} row>
          <FormControlLabel value="1" control={<Radio />} label="1" style={{ marginRight: '16px' }}/>
          <FormControlLabel value="2" control={<Radio />} label="2"  style={{ marginRight: '16px' }}/>
          <FormControlLabel value="3" control={<Radio />} label="3"  style={{ marginRight: '16px' }}/>
          <FormControlLabel value="4" control={<Radio />} label="4"  style={{ marginRight: '16px' }}/>
        </RadioGroup>
             
            </FormControl>
          </Grid>
         
        </Grid>

        

        
      </form>
  
      </Container>
    </div>
  );
};

export default AreaDes;

