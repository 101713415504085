
import React, { useEffect, useRef, useState } from "react";

import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const CaptureImage = ({ handleSubmission, setUploadedFile, dataURLtoFile }) => {

  const [, setImageSrc] = useState('https://i.ibb.co/RjYk1Ps/2817290-eps-1.png');
  const [, setimageValue] = useState('');
  const [, setComponentChange] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [zoomValue, setZoomValue] = useState(1);

  const videoRef = useRef(null);
  const overlayRef = useRef(null);
  const [focusCoordinates, setFocusCoordinates] = useState({ x: 0, y: 0, size: 100 }); // Initial focus coordinates and size

  useEffect(() => {
    navigator.permissions.query({ name: 'camera' })
      .then(permissionStatus => {
  
        setHasPermission(permissionStatus.state === 'granted');
      });
  }, []);
  CaptureImage.propTypes = {
    handleSubmission: PropTypes.func.isRequired,
  };


  useEffect(() => {
    if (hasPermission) {
      getVideo();
    }
  }, [hasPermission]);

  useEffect(() => {
    navigator.permissions.query({ name: 'camera' })
      .then(permissionStatus => {
       
        setHasPermission(permissionStatus.state === 'granted');
      });

    getVideo();
  }, [videoRef]);

  const handleCamera = async () => {
    try {
      const constraints = { video: { facingMode: "environment" } };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const video = videoRef.current;
      if (video) {
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          if (video.readyState >= video.HAVE_ENOUGH_DATA) {
            video.play();
          }
        };
        video.width = window.innerWidth;
        video.height = window.innerHeight;
      }
    } catch (error) {
     
      console.error("Error accessing back camera:", error);
    }

  }

  const getVideo = () => {
    let video = videoRef.current;
    if (video && !video.srcObject) {
      navigator.mediaDevices
        .getUserMedia({ video: { width: window.innerWidth, height: window.innerWidth } })
        .then(stream => {
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            if (video.readyState >= video.HAVE_ENOUGH_DATA) {
              video.play();
            }
          };
        })
        .catch(err => {
          console.error("error:", err);
        });
    }
  };

  function goBack() {
    const video = videoRef.current;

    // Stop the video tracks
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach(track => {
        track.stop(); // Stop each track in the stream
      });

      video.srcObject = null; // Clear the video source object
    }
    handleSubmission()
  }
  const handleZoomSliderChange = event => {
    setZoomValue(parseFloat(event.target.value));
    const video = videoRef.current;
    if (video) {
      const rect = videoRef.current.getBoundingClientRect();
      const x = (focusCoordinates.x / focusCoordinates.size) * (rect.width * event.target.value);
      const y = (focusCoordinates.y / focusCoordinates.size) * (rect.height * event.target.value);
      const size = focusCoordinates.size * event.target.value;

      setFocusCoordinates({ x, y, size });
      video.style.transform = `scale(${event.target.value})`;
    }
  };
  const takePhoto = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    // Draw video frame onto canvas
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0);
    const video = videoRef.current;

    // Stop the video tracks
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach(track => {
        track.stop(); // Stop each track in the stream
      });

      video.srcObject = null; // Clear the video source object
    }
    // Get image data URL from canvas
    const dataURL = canvas.toDataURL('image/png');
    setUploadedFile({ target: { files: [dataURLtoFile(dataURL)] } });
    setImageSrc(dataURL);
    setimageValue(dataURL);
    setComponentChange(false);
    handleSubmission();
  };
  const handleVideoClick = (event) => {
    if (videoRef.current) {
      videoRef.current.focus();
      // Calculate coordinates within the video element
      const rect = videoRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const size = 100; // Change this to adjust the size of the focused area
      setFocusCoordinates({ x, y, size });
    }
  };

  const overlayStyle = {
    position: 'absolute',
    border: '2px solid red',
    pointerEvents: 'none', // To prevent overlay from intercepting clicks
    zIndex: 3,
    left: focusCoordinates.x + 'px',
    top: focusCoordinates.y + 'px',
    width: focusCoordinates.size + 'px',
    height: focusCoordinates.size + 'px',
    display: focusCoordinates.size > 0 ? 'block' : 'none' // Hide the box when size is 0
  };
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'black', overflow: 'hidden' }}>

      <div style={{ margin: '10px', borderRadius: '8px' }}>
        <video ref={videoRef} style={{ width: '100%', height: 'calc(100vh - 50px)', borderRadius: '8px' }} tabIndex={0} onClick={handleVideoClick} />
        <div ref={overlayRef} style={overlayStyle}></div>
      </div>
      <div style={{
        position: 'absolute',
        bottom: '80px',
        left: '0',
        zIndex: 2,
        width: '100%',
        textAlign: 'center'
      }}>
        <input
          type="range"
          min="1"
          max="2"
          step="0.1"
          value={zoomValue}
          onChange={handleZoomSliderChange}
          style={{ width: '200px' }}
        />
      </div>
      <div style={{
        position: 'absolute',
        bottom: '0px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        zIndex: 2,
        background: '#fff',
        padding: '10px'
      }}>

        <div style={{ marginLeft: 25, float: 'left' }}>
          <Button variant="outlined" onClick={handleCamera} style={{ color: '#203468', marginRight: '8px', borderColor: '#203468' }}>
            Switch
          </Button>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button variant="outlined" onClick={takePhoto} style={{ color: '#203468', marginRight: '8px', borderColor: '#203468' }}>
            Take Picture
          </Button>
        </div>
        <div style={{ float: 'right', marginRight: 25 }}>
          <Button variant="outlined" onClick={goBack} style={{ color: '#203468', marginRight: '8px', borderColor: '#203468' }}>
            Back
          </Button>
        </div>
      </div>
    </div>
  );

};

export default CaptureImage;
