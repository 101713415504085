import { atom } from 'recoil'
export const user = atom({
    key: "user",
    default: {
      sgid: '',
      name: '',
      lastName: '',
      id: '',
      role: '',
      roleId: '',
      zone: '',
    },
  });
  export const menuList = atom({
    key: "menuList",
    default: "analytics",
  });
  export const drawerMode = atom({
    key: 'drawerMode',
    default: false
  });
  export const activeStep = atom({
    key: 'activeStep',
    default: 0
  });
  
  export const flashMemoEditorContentState = atom({
    key: 'flashMemoEditorContentState',
    default:{}, 
  });
  export const objectiveEditorContentState = atom({
    key: 'objectiveEditorContentState',
    default: {}, 
  });
  export const flashMemoImageUrlsState = atom({
    key: 'flashMemoImageUrlsState',
    default: {}, // An object to store image URLs, e.g., { flashMemoEditor: 'url' }
  });
  
  export const objectiveImageUrlsState = atom({
    key: 'objectiveImageUrlsState',
    default: {}, // An object to store image URLs, e.g., { objectiveEditor: 'url' }
  });

  export const plant = atom({

    key: 'plant',

    default: '',

  });
  export const reportName = atom({

    key: 'reportName',

    default: '',
  });
  export const viewedReportDataState = atom({

    key: 'viewedReportDataState',

    default: {} ,

  });
  export const viewedReportDataStateupdate = atom({

    key: 'viewedReportDataStateupdate',

    default: {} ,

  });
  export const reportboolean = atom({

    key: 'reportboolean',

    default: false ,

  });
  export const plantId = atom({
    key: 'plantId',
    default: '',
  });
  export const processPlantId = atom({
    key: 'processPlantId',
    default: '',
  });
  export const LocationId=atom({
    key: 'LocationId',
    default: '',
  })

  export const Customplant = atom({

    key: 'Customplant',

    default: '',

  });
  export const CustomreportName = atom({

    key: 'CustomreportName',

    default: '',
  });
  export const selectedAreasState = atom({

    key: 'selectedAreasState',

    default: [], // Initialize as an empty array

  });
  export const selectedCreatedByState = atom({
    key: 'selectedCreatedByState',
    default: [],
  });
  
  export const selectAllState = atom({
    key: 'selectAllState',
    default: false,
  });

  export const CustomselectedCreatedByState = atom({
    key: 'CustomselectedCreatedByState',
    default: [],
  });
  
  export const CustomselectAllState = atom({
    key: 'CustomselectAllState',
    default: false,
  });

  export const selectedActionTakenByState = atom({
    key: 'selectedActionTakenByState',
    default: [],
  });
  
  export const selectAllActionState = atom({
    key: 'selectAllActionState',
    default: false,
  });


  export const userPlantState = atom({
    key: 'userPlant',
    default: localStorage.getItem('user_plant') || '',
  });
  export const userProcessPlantState = atom({
    key: 'userProcessPlant',
    default: localStorage.getItem('user_process_plant') || '',
  });
  export const userLocationState = atom({
    key: 'userLocation',
    default: localStorage.getItem('location_id') || {},
  });
  export const Comments = atom({

    key: 'Comments',

    default: '',
  });

  export const UserSettings = atom({

    key: 'UserSettings',

    default: '',
  });