import React, { useState,useEffect} from 'react';

import { useRecoilState } from 'recoil';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { viewedReportDataState} from '../../../../recoil/atoms';

const WorkDes = ({ formData, setFormData}) => {
  
  const { WorkDesValue } = formData;
  const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);
  const [initialWorkDesData, setInitialWorkDesData] = useState('');
  useEffect(() => {
    if (viewedReportData.workDescription !== undefined && viewedReportData.workDescription !== initialWorkDesData) {
      setInitialWorkDesData(viewedReportData.workDescription);
      setFormData({ ...formData, WorkDesValue: viewedReportData.workDescription });
    }
  }, [viewedReportData.workDescription,initialWorkDesData, formData, setFormData]);

  const handleEditorChange = (field, editor) => {
    let content = editor.getData();
    
    // Remove automatic hyperlinks
    content = content.replace(/<a[^>]*>.*?<\/a>/gi, '');
    const updatedViewedReportData = { ...viewedReportData, workDescription:content };
    setViewedReportData(updatedViewedReportData);
    setFormData({ ...formData, WorkDesValue:content });
    
  };
  const editorConfig = {
   
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
      // Add other configuration options as needed
      
  };
  return (
    <div>
       <CKEditor
                editor={ ClassicEditor }
                config={editorConfig}
                data={viewedReportData.workDescription !== undefined ? viewedReportData.workDescription : WorkDesValue}
                onChange={(event, editor) => {
                    handleEditorChange('editorContent', editor); // Update content in state on change
                }}   
            />
    </div>
  );
};

export default WorkDes;










// import React, { useState,useEffect} from 'react';
// import { Button, TextField, RadioGroup, FormControlLabel, Radio,TextareaAutosize,Container} from '@mui/material';
// import { useRecoilState } from 'recoil';

// import { viewedReportDataState} from '../../../../recoil/atoms';
// const WorkDes = ({ formData, setFormData}) => {
  
//   const { WorkDesValue } = formData;
//   const [viewedReportData, setViewedReportData] = useRecoilState(viewedReportDataState);

//   useEffect(() => {
//     if (viewedReportData.workDescription !== WorkDesValue) {
//       setFormData({ ...formData, WorkDesValue: viewedReportData.workDescription });
//     }
//   }, [viewedReportData.workDescription, WorkDesValue]);
//   const handleWorkChange = (event) => {
//     const updatedViewedReportData = { ...viewedReportData, workDescription: event.target.value };
//     setViewedReportData(updatedViewedReportData);
//     setFormData({ ...formData, WorkDesValue: event.target.value });
//   };

//   return (
//     <div>
//       <Container  style={{  bottom: 0, left: 0, right: 0, padding: '16px'}}>
//       <form >
        
//       <TextareaAutosize
      
//       style={{
//         flex: '1',
//         resize: 'none',
//         width: '100%',
//         minHeight: '100%',
//         border: 'none',
//         borderRadius: '4px',
//         padding: '10px',
//         backgroundColor:'#f2f2f2',
//       }} 
//             value={WorkDesValue}
//             onChange={handleWorkChange} 
//           />

//       </form>
//       </Container>
//     </div>
//   );
// };

// export default WorkDes;